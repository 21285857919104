import { useMemo } from 'react'

import useGoUpdateModuleMarkups from './useGoUpdateModuleMarkups'

const useGoUpdateModuleMarkup = ({
  moduleMarkup,
  projectId,
  moduleId,
  undoRedoStack,
}) => {

  moduleId = moduleId || moduleMarkup.moduleId

  const [ goUpdate, goDelete ] = useGoUpdateModuleMarkups({
    projectId,
    moduleId,
    undoRedoStack,
  })

  const toReturn = useMemo(
    () => ([
      (updateObj={}, options) => (
        goUpdate(
          [ updateObj ],
          moduleMarkup && [ moduleMarkup ],
          options,
        )[0]
      ),
      ...(moduleMarkup ? [ () => goDelete([ moduleMarkup ]) ] : []),
    ]),
    [ moduleMarkup, goUpdate, goDelete ],
  )

  return toReturn
}

export default useGoUpdateModuleMarkup