import { memo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  mergeRegister,
  $findMatchingParent,
  $getNearestNodeOfType,
} from '@lexical/utils'
import {
  $setBlocksType,
} from '@lexical/selection'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  KEY_TAB_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical'
import {
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  $isListNode,
  ListNode
} from '@lexical/list'

import useRefState from '../../hooks/useRefState'
import { equalObjs } from '../../utils/misc'

const LOW_PRIORITY = 1

const ITEM_INFO = {
  bold: {
    Icon: FormatBoldIcon,
    command: FORMAT_TEXT_COMMAND,
    payload: 'bold',
  },
  italic: {
    Icon: FormatItalicIcon,
    command: FORMAT_TEXT_COMMAND,
    payload: 'italic',
  },
  underline: {
    Icon: FormatUnderlinedIcon,
    command: FORMAT_TEXT_COMMAND,
    payload: 'underline',
  },
  strikethrough: {
    Icon: FormatStrikethroughIcon,
    command: FORMAT_TEXT_COMMAND,
    payload: 'strikethrough',
  },
  left: {
    Icon: FormatAlignLeftIcon,
    command: FORMAT_ELEMENT_COMMAND,
    payload: 'left',
  },
  center: {
    Icon: FormatAlignCenterIcon,
    command: FORMAT_ELEMENT_COMMAND,
    payload: 'center',
  },
  right: {
    Icon: FormatAlignRightIcon,
    command: FORMAT_ELEMENT_COMMAND,
    payload: 'right',
  },
  justify: {
    Icon: FormatAlignJustifyIcon,
    command: FORMAT_ELEMENT_COMMAND,
    payload: 'justify',
  },
  bullet: {
    Icon: FormatListBulletedIcon,
    command: INSERT_UNORDERED_LIST_COMMAND,
  },
  number: {
    Icon: FormatListNumberedIcon,
    command: INSERT_ORDERED_LIST_COMMAND,
  },
  undo: {
    Icon: UndoIcon,
    command: UNDO_COMMAND,
  },
  redo: {
    Icon: RedoIcon,
    command: REDO_COMMAND,
  },
}

const SIMPLE_ITEMS = [ `bold`, `italic`, `strikethrough`, `bullet`, `number` ]
const COMPLEX_ITEMS = Object.keys(ITEM_INFO)

const Container = styled.div`
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 4px;
`

const StyledIconButton = styled(IconButton)`
  padding: 7px;
  color: ${({ $selectionIsOn, theme }) => $selectionIsOn ? theme.palette.primary.main : theme.palette.text.secondary};
  
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const ToolbarPlugin = ({
  complex,
  items,
  className,
}) => {

  items = items || (complex ? COMPLEX_ITEMS : SIMPLE_ITEMS)

  const [ editor ] = useLexicalComposerContext()
  const [ selectionOnItems, setSelectionOnItems, getSelectionOnItems ] = useRefState([])
  const [ disabledItems, setDisabledItems, getDisabledItems ] = useRefState([ `undo`, `redo` ])

  const onClick = useCallback(
    ({ target }) => {
      const item = target.closest('[data-item]')?.getAttribute('data-item')
      const { command, payload } = ITEM_INFO[item] || {}
      if(command) {
        if(
          [ `bullet`, `number` ].includes(item)
          && getSelectionOnItems().includes(item)
        ) {
          editor.update(() => {
            const selection = $getSelection()
            if($isRangeSelection(selection)) {
              $setBlocksType(selection, () => $createParagraphNode())
            }
          })
        } else {
          editor.dispatchCommand(command, payload)
        }
      }
    },
    [ editor, getSelectionOnItems ],
  )

  useEffect(
    () => {

      const updateToolbar = () => {

        const selection = $getSelection()

        if($isRangeSelection(selection)) {

          // detect text formatting
          const newSelectionOnItems = (
            [
              `bold`,
              `italic`,
              `underline`,
              `strikethrough`,
            ].filter(item => selection.hasFormat(item))
          )

          const anchorNode = selection.anchor.getNode()
          const element = (
            anchorNode.getKey() === 'root'
              ? anchorNode
              : $findMatchingParent(anchorNode, (e) => {
                  const parent = e.getParent()
                  return parent !== null && $isRootOrShadowRoot(parent)
                })
          ) || anchorNode.getTopLevelElementOrThrow()

          const elementKey = element.getKey()
          const elementDOM = editor.getElementByKey(elementKey)

          // detect list formatting
          if(elementDOM !== null && $isListNode(element)) {
            const parentList = $getNearestNodeOfType(anchorNode, ListNode)
            const type = parentList ? parentList.getListType() : element.getListType()
            newSelectionOnItems.push(type)
          }

          if(!equalObjs(newSelectionOnItems, getSelectionOnItems())) {
            setSelectionOnItems(newSelectionOnItems)
          }

        }

        // FROM EXAMPLE HERE: https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx

        // const selection = $getSelection();
        // if ($isRangeSelection(selection)) {
        //   if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
        //     const rootElement = activeEditor.getRootElement();
        //     setIsImageCaption(
        //       !!rootElement?.parentElement?.classList.contains(
        //         'image-caption-container',
        //       ),
        //     );
        //   } else {
        //     setIsImageCaption(false);
        //   }
    
        //   const anchorNode = selection.anchor.getNode();
        //   let element =
        //     anchorNode.getKey() === 'root'
        //       ? anchorNode
        //       : $findMatchingParent(anchorNode, (e) => {
        //           const parent = e.getParent();
        //           return parent !== null && $isRootOrShadowRoot(parent);
        //         });
    
        //   if (element === null) {
        //     element = anchorNode.getTopLevelElementOrThrow();
        //   }
    
        //   const elementKey = element.getKey();
        //   const elementDOM = activeEditor.getElementByKey(elementKey);
    
        //   setIsRTL($isParentElementRTL(selection));
    
        //   // Update links
        //   const node = getSelectedNode(selection);
        //   const parent = node.getParent();
        //   if ($isLinkNode(parent) || $isLinkNode(node)) {
        //     setIsLink(true);
        //   } else {
        //     setIsLink(false);
        //   }
    
        //   const tableNode = $findMatchingParent(node, $isTableNode);
        //   if ($isTableNode(tableNode)) {
        //     setRootType('table');
        //   } else {
        //     setRootType('root');
        //   }
    
        //   if (elementDOM !== null) {
        //     setSelectedElementKey(elementKey);
        //     if ($isListNode(element)) {
        //       const parentList = $getNearestNodeOfType<ListNode>(
        //         anchorNode,
        //         ListNode,
        //       );
        //       const type = parentList
        //         ? parentList.getListType()
        //         : element.getListType();
        //       setBlockType(type);
        //     } else {
        //       const type = $isHeadingNode(element)
        //         ? element.getTag()
        //         : element.getType();
        //       if (type in blockTypeToBlockName) {
        //         setBlockType(type as keyof typeof blockTypeToBlockName);
        //       }
        //       if ($isCodeNode(element)) {
        //         const language =
        //           element.getLanguage() as keyof typeof CODE_LANGUAGE_MAP;
        //         setCodeLanguage(
        //           language ? CODE_LANGUAGE_MAP[language] || language : '',
        //         );
        //         return;
        //       }
        //     }
        //   }
        //   // Handle buttons
        //   setFontColor(
        //     $getSelectionStyleValueForProperty(selection, 'color', '#000'),
        //   );
        //   setBgColor(
        //     $getSelectionStyleValueForProperty(
        //       selection,
        //       'background-color',
        //       '#fff',
        //     ),
        //   );
        //   setFontFamily(
        //     $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'),
        //   );
        //   let matchingParent;
        //   if ($isLinkNode(parent)) {
        //     // If node is a link, we need to fetch the parent paragraph node to set format
        //     matchingParent = $findMatchingParent(
        //       node,
        //       (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
        //     );
        //   }
    
        //   // If matchingParent is a valid node, pass it's format type
        //   setElementFormat(
        //     $isElementNode(matchingParent)
        //       ? matchingParent.getFormatType()
        //       : $isElementNode(node)
        //       ? node.getFormatType()
        //       : parent?.getFormatType() || 'left',
        //   );
        // }
        // if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        //   // Update text format
        //   setIsBold(selection.hasFormat('bold'));
        //   setIsItalic(selection.hasFormat('italic'));
        //   setIsUnderline(selection.hasFormat('underline'));
        //   setIsStrikethrough(selection.hasFormat('strikethrough'));
        //   setIsSubscript(selection.hasFormat('subscript'));
        //   setIsSuperscript(selection.hasFormat('superscript'));
        //   setIsCode(selection.hasFormat('code'));
    
        //   setFontSize(
        //     $getSelectionStyleValueForProperty(selection, 'font-size', '15px'),
        //   );
        // }

      }

      const updateDisabled = item => payload => {
        const oldDisabled = getDisabledItems().includes(item)
        if(oldDisabled && payload) {
          setDisabledItems(getDisabledItems().filter(i => i !== item))
        } else if(!oldDisabled && !payload) {
          setDisabledItems([ ...getDisabledItems(), item ])
        }
        return false
      }

      return mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar()
          })
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          (_payload, _newEditor) => {
            updateToolbar()
            return false
          },
          LOW_PRIORITY,
        ),
        editor.registerCommand(
          CAN_UNDO_COMMAND,
          updateDisabled(`undo`),
          LOW_PRIORITY,
        ),
        editor.registerCommand(
          CAN_REDO_COMMAND,
          updateDisabled(`redo`),
          LOW_PRIORITY,
        ),
        editor.registerCommand(
          KEY_TAB_COMMAND,
          event => {
            event.preventDefault()
            return editor.dispatchCommand(
              event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND,
            )
          },
          COMMAND_PRIORITY_EDITOR,
        )
      )

    },
    [ editor, getDisabledItems, getSelectionOnItems, setDisabledItems, setSelectionOnItems ],
  )
  
  return (

    <Container
      className={`ToolbarPlugin-Container ${className}`}
    >
      <ButtonContainer
        className={`ToolbarPlugin-ButtonContainer`}
      >

        {items.map(item => {
          const Icon = ITEM_INFO[item].Icon
          return (
            <StyledIconButton
              key={item}
              data-item={item}
              $selectionIsOn={selectionOnItems.includes(item)}
              disabled={disabledItems.includes(item)}
              onClick={onClick}
              tabIndex={-1}
            >
              {<Icon />}
            </StyledIconButton>
          )
        })}

      </ButtonContainer>
    </Container>
  )
}

export default memo(ToolbarPlugin)