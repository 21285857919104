import { memo, useContext } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import Button from '@material-ui/core/Button'
import queryString from 'query-string'
import { useApolloClient } from '@apollo/client'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import userQuery from '../../../graphql/queries/user'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import VideoPlayer from '../../common/VideoPlayer'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 36px;
  vertical-align: top;
  margin: 5px -4px 0 -6px;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 80px;
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoImg = styled.img`
  width: 300px;
  margin: 40px 0 0;
`

const BigIdea = styled.div`
  text-align: center;
  font-weight: 300;
  margin: -10px 0 10px;
`

const PointContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding: 10px 20px;
  background: ${({ theme, $color }) => theme.palette[$color].main};
  width: 400px;
  max-width: min(100%, calc(100vw - 20px));
  margin-top: 50px;
`

const Number = styled.div`
  background: white;
  color: ${({ theme, $color }) => theme.palette[$color].main};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  margin: 0 20px 0 0;
`

const Point = styled.div`
  font-size: 20px;
  line-height: 1.3;
  padding: 3px 0;
  color: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  flex: 1;

  @media (max-width: 650px) {
    font-size: 17px;
  }
`

const Methods = styled.div`
  margin: 10px 0 20px;
  display: flex;
  flex-wrap: wrap;
  width: 1050px;
  gap: 0 50px;

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
    flex-wrap: none;
    width: 100%;
  }
`

const Method = styled.div`
  position: relative;
  width: 500px;
  max-width: min(100%, calc(100vw - 20px));
  display: flex;
  flex-direction: column;
  align-items: center;
`

const EquipNote = styled.div`
  width: 100%;
  margin: 20px 0 0;
  display: flex;
  justify-content: center;
`

const EquipNoteBox = styled.div`
  font-size: 14px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 0 15px;
  line-height: 30px;
  border-radius: 15px;
`

const EquipLogoImg2 = styled.img`
  height: 27px;
  vertical-align: top;
  margin: 1px -6px;
  transition: transform .15s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`

const LabelImage = styled.img`
  height: 70px;
  margin-top: 25px;
`

const MethodSynopsis = styled.div`
  margin-bottom: 20px;
`

const MethodVideo = styled(VideoPlayer)`
`

const MethodVideoLabel = styled.div`
  font-weight: 300;
  margin: 2px 0 10px;
  font-size: 13px;
`

// const NotesImage = styled.img`
//   width: 593px;
//   max-width: calc(100vw - 40px);
//   border: 1px solid ${({ theme }) => theme.palette.divider};
//   opacity: .7;
// `

const Usage = styled.div`
  position: relative;
  margin: 30px 0 10px;
  display: flex;
  flex-direction: column;
`

const UsageExamples = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`

const UsageExample = styled.div`
  text-align: center;
  margin: 10px 0;
`

const UsageExampleLine1 = styled.div`
  font-weight: 500;
`

const UsageExampleLine2 = styled.div`
  font-weight: 300;
`

const GetTools = styled.div`
  background-size: 103% 160%;
  background-position: 0px 0px,0px 0px;
  background-image: url("/underline_scribble.svg");
  padding: 130px 50px;
  margin: -80px -50px 200px;
`

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-size: 16px;
`

const ButtonLogoImg = styled.img`
  height: 35px;
  vertical-align: top;
  margin: -2px -6px 0 0;
`

// const OrganizeImage = styled.img`
// `

const Gif = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &:not([src]) {
    display: none;
  }
`

const GifSection = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  margin-bottom: 25px;
  width: 602px;
  max-width: calc(100vw - 20px);
`

const GifContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  background: white;
`

const WantToLearnForFree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`

const WantToLearnForFreeText = styled.div`
  text-align: center;
`

const WantToLearnForFreeCourses = styled.div`
  margin: 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  transition: transform .15s ease-in-out;

  &:hover {
    transform: scale(1.03);
  }
`

const MiniCourseImg = styled.img`
  width: 260px;
  vertical-align: middle;
`

const AboutTools = ({ ...props }) => {

  let {
    'purchase-successful': purchaseSuccessful,
    // 'subscription-granted': subscriptionGranted,
  } = queryString.parse(window.location.search)
  purchaseSuccessful = purchaseSuccessful !== undefined
  // subscriptionGranted = subscriptionGranted !== undefined

  const client = useApolloClient()
  const user = useContext(LoggedInUserContext)
  const getUser = useInstanceValue(user)
  const [ setDoUserQueryTimeout ] = useSetTimeout()

  useEffectAsync(
    // Immediately after purchase, keep doing user queries until stripe subscription is found.
    () => {
      if(!purchaseSuccessful) return

      let timesChecked = 0

      const checkAndTryAfterTimeout = () => {
        setDoUserQueryTimeout(
          async () => {
            const user = getUser()
            if(!user) return

            const hasStripeSubscription = !!(user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]

            if(!hasStripeSubscription) {
              await client.query({
                context: {
                  isBackupServerQuery: true,  // forcing the await to wait until run through the server via this option
                  forceSaveServerResultToDexie: true,    
                },
                query: userQuery,
                fetchPolicy: `network-only`,
              })
              if(++timesChecked < 10) {
                checkAndTryAfterTimeout()
              }
            }
          },
          1000,
        )
      }

      checkAndTryAfterTimeout()
    },
    [],
  )

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("About {{logo}}", {
            logo: <HeaderLogoImg src="/biblearc_tools_1.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("TOOLS")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          <LogoImg src="/biblearc_tools_2.svg" />

          <BigIdea>
            {i18n("“Visual Meditations” using graphical Bible study methods.")}
          </BigIdea>

          <PointContainer $color="secondary">

            <Number $color="secondary">
              {i18n("1")}
            </Number>

            <Point>
              {i18n("Create Bible study projects with proven, graphical study methods.")}
            </Point>

          </PointContainer>

          <Methods>

            <Method>

              <LabelImage src="/arcing_label.svg" />

              <MethodSynopsis>
                {i18n("Understand the logic of the text.")}
              </MethodSynopsis>

              <MethodVideo
                src="https://player.vimeo.com/video/169513805?h=1&color=bdac59&title=1&byline=0"
              />

              <MethodVideoLabel>
                {i18nReact("“Devotional: For fellowship and joy” from the {{arcing_course}}", {
                  arcing_course: (
                    <NavLinkOrAWithDisable
                      to="https://equip.biblearc.com/course/arcing"
                    >
                      {i18n("Arcing course")}
                    </NavLinkOrAWithDisable>
                  )
                })}
              </MethodVideoLabel>

            </Method>

            <Method>

              <LabelImage src="/bracketing_label.svg" />

              <MethodSynopsis>
                {i18n("Determine the main point and see how it is supported.")}
              </MethodSynopsis>

              <MethodVideo
                src="https://player.vimeo.com/video/219002703?h=1&color=bdac59&title=1&byline=0"
              />

              <MethodVideoLabel>
                {i18nReact("{{bracketing_course}} promotional video", {
                  bracketing_course: (
                    <NavLinkOrAWithDisable
                      to="https://equip.biblearc.com/course/bracketing"
                    >
                      {i18n("Bracketing course")}
                    </NavLinkOrAWithDisable>
                  )
                })}
              </MethodVideoLabel>

            </Method>

            <EquipNote>
              <EquipNoteBox>
                {i18nReact("Note: Courses require a {{biblearc_equip}} subscription.", {
                  biblearc_equip: (
                    <NavLinkOrAWithDisable
                      to="https://equip.biblearc.com"
                      sameTab
                    >
                      <EquipLogoImg2 src="/biblearc_equip_1.svg" />
                    </NavLinkOrAWithDisable>
                  ),
                })}
              </EquipNoteBox>
            </EquipNote>

            <Method>

              <LabelImage src="/phrasing_label.svg" />

              <MethodSynopsis>
                {i18n("Follow the flow through every phrase in a passage.")}
              </MethodSynopsis>

              <MethodVideo
                src="https://player.vimeo.com/video/117385045?h=1&color=bdac59&title=1&byline=0"
              />

              <MethodVideoLabel>
                {i18nReact("Introduction to Biblearc’s {{phrasing}} study module", {
                  phrasing: (
                    <NavLinkOrAWithDisable
                      to="https://equip.biblearc.com/course/phrasing"
                    >
                      {i18n("Phrasing")}
                    </NavLinkOrAWithDisable>
                  )
                })}
              </MethodVideoLabel>

            </Method>

            <Method>

              <LabelImage src="/diagramming_label.svg" />

              <MethodSynopsis>
                {i18n("Account for every word of Scripture.")}
              </MethodSynopsis>

              <MethodVideo
                src="https://player.vimeo.com/video/137123966?h=1&color=bdac59&title=1&byline=0"
              />

              <MethodVideoLabel>
                {i18nReact("“Grammatical Flow of Languages” from the {{learning_resources}}", {
                  learning_resources: (
                    <NavLinkOrAWithDisable
                      to="https://equip.biblearc.com/learningresources"
                    >
                      {i18n("Learning Resources video library")}
                    </NavLinkOrAWithDisable>
                  )
                })}
              </MethodVideoLabel>

            </Method>

            <Method>

              <LabelImage src="/markup_label.svg" />

              <MethodSynopsis>
                {i18n("See More Details. Ask Better Questions.")}
              </MethodSynopsis>

              <MethodVideo
                src="https://player.vimeo.com/video/1007315519?h=1&color=bdac59&title=1&byline=0"
              />

              <MethodVideoLabel>
                {i18nReact("From our {{markup_course}}", {
                  markup_course: (
                    <NavLinkOrAWithDisable
                      to="https://equip.biblearc.com/course/markup"
                    >
                      {i18n("Markup course")}
                    </NavLinkOrAWithDisable>
                  )
                })}
              </MethodVideoLabel>

            </Method>

            {/* <Method>

              <LabelImage src="/outline_label.svg" />

              <MethodSynopsis>
                {i18n("Wrap your mind around an entire book of the Bible.")}
              </MethodSynopsis>

              <MethodVideo
                src="https://player.vimeo.com/video/137123966?h=1&color=bdac59&title=1&byline=0"
              />

              <MethodVideoLabel>
                {i18nReact("“Grammatical Flow of Languages” from the {{learning_resources}}", {
                  learning_resources: (
                    <NavLinkOrAWithDisable
                      to="https://equip.biblearc.com/learningresources"
                    >
                      {i18n("Learning Resources video library")}
                    </NavLinkOrAWithDisable>
                  )
                })}
              </MethodVideoLabel>

            </Method> */}

          </Methods>

          <WantToLearnForFree>

            <WantToLearnForFreeText>
              {i18n("Learn for FREE and get a two-week Biblearc TOOLS trial!")}
            </WantToLearnForFreeText>

            <WantToLearnForFreeCourses>

              <StyledNavLinkOrAWithDisable
                to="https://equip.biblearc.com/course/bible-logic"
              >
                <MiniCourseImg
                  src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1723476957606-BibleLogicMiniCourse4xtiny.jpeg"
                  className="dark-mode-exempt"
                />
              </StyledNavLinkOrAWithDisable>

              <StyledNavLinkOrAWithDisable
                to="https://equip.biblearc.com/course/block-diagramming"
              >
                <MiniCourseImg
                  src="https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1723475816766-BlockDiagrammingMiniCourse4xtiny.jpeg"
                  className="dark-mode-exempt"
                />
              </StyledNavLinkOrAWithDisable>

            </WantToLearnForFreeCourses>
            
          </WantToLearnForFree>

          <PointContainer $color="tertiary">

            <Number $color="tertiary">
              {i18n("2")}
            </Number>

            <Point>
              {i18n("Take notes and compose your thoughts at a whole new level.")}
            </Point>

          </PointContainer>

          <Usage>

            <GifSection>
              <GifContainer>
                <Gif src="/general_promo.gif" />
              </GifContainer>
            </GifSection>

            <UsageExamples>

              <UsageExample>
                <UsageExampleLine1>
                  {i18n("Insert and highlight passages as you")}
                </UsageExampleLine1>
                <UsageExampleLine2>
                  {i18n("ask and answer questions from the text")}
                </UsageExampleLine2>
                {/* Use https://gifcap.dev/ to improve the general promo
                (example animated gif: insert passage, highlight, ask bullet point question) */}
              </UsageExample>
              <UsageExample>
                <UsageExampleLine1>
                  {i18n("Collect insights")}
                </UsageExampleLine1>
                <UsageExampleLine2>
                  {i18n("and keep them organized")}
                </UsageExampleLine2>
                {/* (example animated gif: create a new Notes tab with ordered list and link) */}
              </UsageExample>
              <UsageExample>
                <UsageExampleLine1>
                  {i18n("Write sermons and teaching outlines")}
                </UsageExampleLine1>
                <UsageExampleLine2>
                  {i18n("with everything together in one document")}
                </UsageExampleLine2>
                {/* (example animated gif: use headings, quote, and insert study module) */}
              </UsageExample>

            </UsageExamples>
          </Usage>

          <PointContainer $color="primary">

            <Number $color="primary">
              {i18n("3")}
            </Number>

            <Point>
              {i18n("Easily share your work and conclusions with others.")}
            </Point>

          </PointContainer>

          <Usage>

            <UsageExample>
              <UsageExampleLine1>
                {i18n("Present the conclusions of your study")}
              </UsageExampleLine1>
              <UsageExampleLine2>
                {i18n("to persuade others and receive feedback from friends")}
              </UsageExampleLine2>
              {/* (Naselli book example)
              (Example from me) */}
            </UsageExample>
            <UsageExample>
              <UsageExampleLine1>
                {i18n("Use Biblearc in the classroom")}
              </UsageExampleLine1>
              <UsageExampleLine2>
                {i18n("by asking students to share their work with you")}
              </UsageExampleLine2>

              {/* {i18n("Simply ask students to share their completed work with the instructor.")}
              {i18n("(For a fully integrated classroom experience, create online courses with Biblearc tasks, using the same engine behind Biblearc EQUIP.)")}
              {i18n("Gospel Paths Premium")} */}
            </UsageExample>
          </Usage>

          {/* <PointContainer $color="fourthly">

            <Number $color="fourthly">
              {i18n("4")}
            </Number>

            <Point>
              {i18n("Organize everything in your projects browser.")}
            </Point>

          </PointContainer>

          <Usage>

            {/* <OrganizeImage /> * /}

            <UsageExample>
              <UsageExampleLine1>
                {i18n("Search by project name and passage")}
              </UsageExampleLine1>
              <UsageExampleLine2>
                {i18n("(Full text search of notes coming soon)")}
              </UsageExampleLine2>
            </UsageExample>
            <UsageExample>
              <UsageExampleLine1>
                {i18n("Filter by study module")}
              </UsageExampleLine1>
              <UsageExampleLine2>
                {i18n("To quickly find that diagram from a year ago")}
              </UsageExampleLine2>
            </UsageExample>
            <UsageExample>
              <UsageExampleLine1>
                {i18n("Organize with folders and tags")}
              </UsageExampleLine1>
              <UsageExampleLine2>
                {i18n("(Coming soon)")}
              </UsageExampleLine2>
            </UsageExample>
            <UsageExample>
              <UsageExampleLine1>
                {i18n("Easily return to work shared with you")}
              </UsageExampleLine1>
              <UsageExampleLine2>
                {i18n("(Coming soon)")}
              </UsageExampleLine2>
            </UsageExample>
          </Usage> */}

          <GetTools>
            <NavLinkOrAWithDisable to="/subscribe" exact>
              <StyledButton
                variant="outlined"
              >
                {i18nReact("Get {{thing_to_get}}", {
                  thing_to_get: <ButtonLogoImg src="/biblearc_tools_1.svg" />,
                })}
              </StyledButton>
            </NavLinkOrAWithDisable>
          </GetTools>

        </Content>
      </Container>

    </>
  )
}


export default memo(AboutTools)