import { memo, useCallback, useContext } from 'react'
import { i18n } from 'inline-i18n'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { v4 as uuidv4 } from 'uuid'

import useDragMap from '../../../hooks/useDragMap'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useRefState from '../../../hooks/useRefState'
import useMapLayers from '../../../hooks/useMapLayers'
import { placeTypeOptions } from './mapDataIsValid'
import { preventDefaultEvent, equalObjs } from '../../../utils/misc'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import Header from "../../common/Header"
import AppContent from "../../common/AppContent"
import BibleMapEditorSnackbar from './BibleMapEditorSnackbar'
import CustomSwitch from '../../common/CustomSwitch'
import BibleMapPlace from './BibleMapPlace'

export const getDefaultNames = (name=``) => ([{
  id: uuidv4(),
  name,
}])

export const GET_DEFAULT_PLACE = () => ({
  names: getDefaultNames(),
  events: [],
  type: placeTypeOptions[0],
})

export const GET_DEFAULT_EVENT = () => ({
  names: getDefaultNames(),
  dates: [{}],
  passages: [],
  personIds: [],
})

export const GET_DEFAULT_JOURNEY = name => ({
  id: uuidv4(),
  names: getDefaultNames(name),
})

export const GET_DEFAULT_PERSON = name => ({
  id: uuidv4(),
  names: getDefaultNames(name),
})

const StyledHeader = styled(Header)`
  position: relative;
  z-index: 2;
  background-color: rgb(255 255 255/.4);
  backdrop-filter: blur(3px);
`

const StyledAppContent = styled(AppContent)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $opacity }) => $opacity};
`

const MapBGPositionAdjuster = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 0 0;
`

const MapBGScaleAdjuster = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 0 0;
`

const MapImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const PublishButton = styled(Button)`
  margin: 5px;
`

const StyledCustomSwitch = styled(CustomSwitch)`
  margin: 5px 10px;
`

const Coming = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  font-size: 26px;
  font-weight: 300;
  background: white;
`

const BibleMap = ({ ...props }) => {

  const { pathname } = useLocation()
  console.log('pathname', pathname)
  // const year = pathname.at(-2)
  // const place = pathname.at(-1)

  const layer = `BIBLE`  // TODO: possibly expand to other layers as well

  const user = useContext(LoggedInUserContext)

  const [ viewDraft, toggleViewDraft ] = useSimpleToggle()
  const stage = viewDraft ? `DRAFT` : `PUBLISHED`

  const { getMapLayerState, getMapLayerId, toggleShowConfirmPublish, confirmDialog } = useMapLayers({ layer, stage, toggleViewDraft })

  const [ mapLayer, setMapLayer ] = getMapLayerState({ layer, stage })
  const { places=[], journeys=[], persons=[] } = mapLayer.data || {}

  const [ placeInEdit, setPlaceInEdit, getPlaceInEdit ] = useRefState()
  const closeEdit = useCallback(() => setPlaceInEdit({ ...getPlaceInEdit(), closed: true }), [ setPlaceInEdit, getPlaceInEdit ])
  const { positionAdjusterStyle, scaleAdjusterStyle, getPinStyle, mapViewInfo, moving, ...containerProps } = useDragMap()
  const getMapViewInfo = useInstanceValue(mapViewInfo)
  const [ movingPlace, toggleMovingPlace ] = useSimpleToggle()

  const createNewPlace = useCallback(
    event => {
      preventDefaultEvent(event)
      const { scale, translateX, translateY, top, left, baseMapWidth, baseMapHeight } = getMapViewInfo()

      const totalWidth = baseMapWidth * scale
      const totalHeight = baseMapHeight * scale

      const x = (event.clientX - left - translateX) / totalWidth
      const y = (event.clientY - top - translateY) / totalHeight

      if(movingPlace) {
        setPlaceInEdit({
          ...getPlaceInEdit(),
          locations: [{
            id: uuidv4(),
            x,
            y,
          }],
        })
      } else {
        setPlaceInEdit({
          ...GET_DEFAULT_PLACE(),
          locations: [{
            id: uuidv4(),
            x,
            y,
          }],
        })
      }

    },
    [ getMapViewInfo, setPlaceInEdit, getPlaceInEdit, movingPlace ],
  )

  const editingOpen = !!placeInEdit && !placeInEdit.closed
  const editingNewPlace = editingOpen && !placeInEdit.id

  const publishedMapLayer = getMapLayerState({ layer, stage: `PUBLISHED` })[0]
  const hasUnpublishedDraft = (
    !!mapLayer
    && !!publishedMapLayer
    && !equalObjs(
      mapLayer.data,
      publishedMapLayer.data,
    )
  )

  useEffectAsync(
    () => {
      if(!viewDraft && editingOpen) {
        closeEdit()
      }
    },
    [ viewDraft ],
  )

  return (
    <>

      <StyledHeader
        {...props}
        rightExtraComponent={
          [ 'ADMIN', 'EDITOR' ].includes((user || {}).adminLevel)
            ? (
              <>
                {viewDraft &&
                  <PublishButton
                    color="secondary"
                    variant="contained"
                    disableElevation
                    disabled={editingOpen || !hasUnpublishedDraft}
                    onClick={toggleShowConfirmPublish}
                  >
                    {i18n("Publish")}
                  </PublishButton>
                }
                <StyledCustomSwitch
                  checked={viewDraft}
                  onChange={toggleViewDraft}
                  label={i18n("Draft Mode")}
                />
              </>
            )
            : null
        }
      />

      {!(user || {}).adminLevel &&
        <Coming>Coming soon...</Coming>
      }

      {!!(user || {}).adminLevel &&
        <StyledAppContent
          {...containerProps}
        >

          {!!scaleAdjusterStyle &&
            <MapBGPositionAdjuster
              style={positionAdjusterStyle}
            >
              <MapBGScaleAdjuster
                style={scaleAdjusterStyle}
              >
                <MapImg
                  src="/study_bible_map.svg"
                  onClick={moving ? null : ((viewDraft && (!editingOpen || movingPlace)) ? createNewPlace : closeEdit)}
                />
                {places.map(place => {
                  const inEdit = editingOpen && place.id === (placeInEdit || {}).id
                  if(inEdit && movingPlace) return null
                  return (
                    <BibleMapPlace
                      key={place.id}
                      place={place}
                      setPlaceInEdit={viewDraft && setPlaceInEdit}
                      getStyle={getPinStyle}
                      $inEdit={inEdit}
                      {...(movingPlace ? { onClick: createNewPlace } : {})}
                    />
                  )
                })}
                {editingNewPlace &&
                  <BibleMapPlace
                    place={placeInEdit}
                    getStyle={getPinStyle}
                    $inEdit
                    {...(movingPlace ? { onClick: createNewPlace } : {})}
                  />
                }
              </MapBGScaleAdjuster>
            </MapBGPositionAdjuster>
          }

        </StyledAppContent>
      }

      <BibleMapEditorSnackbar
        open={editingOpen}
        mapLayerId={getMapLayerId({ layer, stage })}
        closeEdit={closeEdit}
        placeInEdit={placeInEdit}
        places={places}
        journeys={journeys}
        persons={persons}
        setMapLayer={setMapLayer}
        setPlaceInEdit={setPlaceInEdit}
        movingPlace={movingPlace}
        toggleMovingPlace={toggleMovingPlace}
      />

      {confirmDialog}

    </>
  )
}

export default memo(BibleMap)