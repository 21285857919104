import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import { preventDefaultEvent } from '../../../utils/misc'
import { getWordObjFromEl, wordIsSameOrBefore } from '../../../hooks/useTextSelectionMarkup'
import useDoubleClickCallback from '../../../hooks/useDoubleClickCallback'

import TextContent from '../../common/TextContent'
import TextContentMarkupContainer from '../../markup/TextContentMarkupContainer'
import MarkupContentTextField from './MarkupContentTextField'
import DotNotes from '../../dot-notes/DotNotes'

const StyledTextContentMarkupContainer = styled(TextContentMarkupContainer)`
  /* position: relative; */
  font-size: ${({ $textFontSize }) => $textFontSize}px;
  line-height: ${({ $lineSpacing }) => $lineSpacing};

  ${({ $showVsNums }) => $showVsNums ? `` : `
    .TextContent-tag-v,
    .TextContent-tag-vp {
      display: none;
    }
  `}

  ${({ $inEditingMode, $paragraphFormatting }) => !$inEditingMode ? `` : `

    .TextContent-tag-w:hover {
      cursor: text;
      opacity: 1;
    }

    ${$paragraphFormatting !== `PARAGRAPHS-AND-HEADINGS` ? `` : `
      user-select: none;
      .TextContent-verse {
        user-select: text;
      }
    `}

  `}

`

const MarkupContent = ({
  projectId,
  isRTL,
  textFontSize,
  lineSpacing,
  showVsNumsSetting,
  showCfsSetting,
  showNotesSetting,
  showCantillationSetting,
  showHebrewVowelsSetting,
  showGreekAccentsSetting,
  showContextSetting,
  greekPunctuationSetting,
  proceedingContextSetting,
  followingContextSetting,
  inEditingMode,
  markup,
  moduleDots,
  setPlacingInfo,
  minimizedKey,
  pieces,
  versionId,
  bookId,
  startChapter,
  endChapter,
  contentRef,
  effectiveModuleWidth,
  paragraphFormatting,
  goSetPopperInfo,
}) => {

  const markupContentModuleDots = useMemo(
    () => (
      minimizedKey
        ? [ ...moduleDots ]
        : moduleDots.filter(({ positionInfo }) => positionInfo.selector !== `[data-dot-container="space-below-key"]`)
    ),
    [ moduleDots, minimizedKey ],
  )

  const onDoubleClick = useDoubleClickCallback(
    event => {
      const { target, clientX, clientY } = event

      // get the relevant word
      const bottomLevelChildNodes = [ ...target.querySelectorAll(`.text-content-word[data-word-loc]`), target.closest(`.text-content-word[data-word-loc]`) ].filter(Boolean)
      const { el: effectiveClickedWordEl } = bottomLevelChildNodes.reduce(
        (closestObj, el) => {
          const { x, y, width, height } = el.getBoundingClientRect()
          const distance = Math.max(
            x - clientX,
            clientX - (x + width),
          )
          if(
            y < clientY
            && y + height > clientY
            && distance < 40
            && (
              !closestObj
              || distance < closestObj.distance
            )
          ) {
            return {
              el,
              distance,
            }
          }
          return closestObj
        },
        null,
      ) || {}

      if(effectiveClickedWordEl) {
        // select the appropriate text
        const word = getWordObjFromEl(effectiveClickedWordEl)
        const matchingMarkup = markup.filter(({ start, end }) => (
          wordIsSameOrBefore(start, word)
          && wordIsSameOrBefore(word, end)
        ))
        const calcDist = ({ start, end }) => (parseInt(end.loc, 10) - parseInt(start.loc, 10)) * 100 + (end.wordNumberInVerse - start.wordNumberInVerse)
        matchingMarkup.sort((a,b) => calcDist(a) < calcDist(b) ? -1 : 1)
        const containerEl = target.closest(`.MarkupContent-StyledTextContentMarkupContainer`)
        if(matchingMarkup[0] && containerEl) {
          const { start, end } = matchingMarkup[0]
          const startWordEl = containerEl.querySelector(`.text-content-word[data-word-loc="${start.loc}:${start.wordNumberInVerse}"]`)
          const endWordEl = containerEl.querySelector(`.text-content-word[data-word-loc="${end.loc}:${end.wordNumberInVerse}"]`)
          if(startWordEl && endWordEl) {
            preventDefaultEvent(event)
            const range = document.createRange()
            const selection = window.getSelection()
            range.setStart(startWordEl, 0)
            range.setEnd(endWordEl, endWordEl.childNodes.length)
            selection.empty()
            selection.addRange(range)
          }
        }
      }
    },
  )

  return (
    <>

      {!!showContextSetting.value &&
        <MarkupContentTextField
          moduleSetting={proceedingContextSetting}
          projectId={projectId}
          disabled={!inEditingMode}
          label={i18n("Proceeding Context Summary")}
          data-dot-container="proceeding-context"
        />
      }

      <StyledTextContentMarkupContainer
        $textFontSize={textFontSize}
        $lineSpacing={lineSpacing}
        $showVsNums={showVsNumsSetting.value}
        $inEditingMode={inEditingMode}
        $paragraphFormatting={paragraphFormatting}
        isRTL={isRTL}
        markup={markup}
        onClick={onDoubleClick}
        className="MarkupContent-StyledTextContentMarkupContainer"
      >

        <TextContent
          showCfs={showCfsSetting.value}
          showNotes={showNotesSetting.value}
          showCantillation={showCantillationSetting.value}
          showHebrewVowels={showHebrewVowelsSetting.value}
          showGreekAccents={showGreekAccentsSetting.value}
          greekPunctuation={greekPunctuationSetting.value}
          pieces={pieces}
          versionId={versionId}
          bookId={bookId}
          startChapter={startChapter}
          // wordClassName="markup-text-word"
          showChapterNumbers={startChapter !== endChapter}
          ignoreClick={inEditingMode ? `word` : false}
          goSetPopperInfo={goSetPopperInfo}
        />

      </StyledTextContentMarkupContainer>

      {!!showContextSetting.value &&
        <MarkupContentTextField
          moduleSetting={followingContextSetting}
          projectId={projectId}
          disabled={!inEditingMode}
          label={i18n("Following Context Summary")}
          data-dot-container="following-context"
        />
      }

      <DotNotes
        projectId={projectId}
        moduleDots={markupContentModuleDots}
        setPlacingInfo={setPlacingInfo}
        containerRef={contentRef}
        inEditingMode={inEditingMode}
        moduleWidth={effectiveModuleWidth}
      />

    </>
  )
}

export default memo(MarkupContent)