import { memo } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
// import EditIcon from '@material-ui/icons/Edit'

import useMapEditorItem from '../../../hooks/useMapEditorItem'

import FadedLoading from '../../common/FadedLoading'
import BibleMapEditorNames from './BibleMapEditorNames'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorLevelOfImportance from './BibleMapEditorLevelOfImportance'

const EventsHeading = styled.div`
  font-size: 16px;
`

const EventRow = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  transition: transform .15s ease-in-out;

  /* &:hover {
    transform: translateX(10px);
    cursor: pointer;
  } */
`

// const StyledEditIcon = styled(EditIcon)`
//   font-size: 14px;
//   margin: -1px 0 0 9px;
// `

const None = styled.div`
  font-style: italic;
  margin: -10px 0 0;
  font-weight: 300;
`

const BibleMapEditorJourney = ({
  mapLayerId,
  journeyInEdit,
  requestingLock,
  lockObtained,
  updating,
  doUpdateMapLayer,
  places,
  ...otherProps
}) => {

  const eventsInJourney = places.map(({ events }) => events.filter(({ journeyId }) => journeyId === journeyInEdit.id)).flat()

  const { onChange, edited, buttons } = useMapEditorItem({
    mapLayerId,
    type: `journey`,
    inEdit: journeyInEdit,
    requestingLock,
    lockObtained,
    updating,
    doUpdateMapLayer,
    hideDelete: eventsInJourney.length > 0,
    ...otherProps,
  })

  const { names, levelOfImportance, about } = edited

  const commonProps = {
    onChange: onChange,
    disabled: !lockObtained || updating,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  return (
    <>

      <BibleMapEditorBody>

        <BibleMapEditorNames
          names={names}
          {...commonProps}
        />

        <TextField
          value={about || ``}
          data-keys={`about`}
          label={i18n("About")}
          multiline
          {...commonProps}
        />

        <BibleMapEditorLevelOfImportance
          levelOfImportance={levelOfImportance || 1}
          {...commonProps}
        />

        <EventsHeading>
          {i18n("Events")}
        </EventsHeading>

        {eventsInJourney.length === 0 &&
          <None>
            {i18n("None")}
          </None>
        }

        {eventsInJourney.map(event => (
          <EventRow
            key={event.id}
            // onClick={() => setEventInEdit(event)}
          >
            {event.names[0].name}
            {` `}
            ({event.dates[0].date})
            {/* <StyledEditIcon /> */}
          </EventRow>
        ))}

        {(requestingLock || updating) && <FadedLoading />}

      </BibleMapEditorBody>

      {buttons}

    </>
  )
}

export default memo(BibleMapEditorJourney)