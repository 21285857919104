import { memo, useCallback } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import { useHistory } from 'react-router-dom'

import RelativeTime from '../common/RelativeTime'

const Container = styled.div`
  margin-bottom: 10px;
  cursor: pointer;

  &:hover .ProjectSearchResultsRow-Name {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
`

const PassageAndModules = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`

const LastOpenedOrModified = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
`

const ProjectSearchResultsRow = ({
  id,
  name,
  openedOrModifiedAt,
  ...other
}) => {

  const history = useHistory()

  const goToProject = useCallback(
    () => {
      history.push(`/project/${id}`)
    },
    [ history, id ],
  )

  return (
    <Container
      onClick={goToProject}
    >

      <Name className="ProjectSearchResultsRow-Name">
        {name}
      </Name>

      <PassageAndModules>
      </PassageAndModules>

      <LastOpenedOrModified>
        <RelativeTime date={openedOrModifiedAt} />
      </LastOpenedOrModified>

    </Container>
  )
}

export default memo(ProjectSearchResultsRow)