import { memo, useCallback, useContext, useState } from 'react'
import { i18n } from 'inline-i18n'
import { useLocation } from "react-router-dom"
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import LaunchIcon from '@material-ui/icons/Launch'
import CheckIcon from '@material-ui/icons/Check'
import copy from 'copy-to-clipboard'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'
import useGoUpdateProject from '../../hooks/useGoUpdateProject'
import useDataQuery from '../../hooks/useDataQuery'
import useAssignmentBlock from '../../hooks/useAssignmentBlock'
import useAppSize from '../../hooks/useAppSize'
import useEqualObjsMemo from '../../hooks/useEqualObjsMemo'
import useSetTimeout from '../../hooks/useSetTimeout'
import { shouldRecommendInstall } from '../../utils/misc'

import SubscribedInfoIcon from "./SubscribedInfoIcon"
import SubscribedIndicator from "./SubscribedIndicator"
import NavLinkOrAWithDisable from "./NavLinkOrAWithDisable"
import RecentProjects from "./RecentProjects"
import RecentChurchBibles from './RecentChurchBibles'
import LinkIconButton from './LinkIconButton'
import MainMenuListeningHistory from './MainMenuListeningHistory'
import MainMenuMyBookmarks from './MainMenuMyBookmarks'
import SecondaryHeaderItems from './SecondaryHeaderItems'

import projectsQuery from '../../graphql/queries/projects'

const Container = styled.div`
  width: 300px;
  max-width: calc(100vw - 50px);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const MenuItemNavLink = styled(NavLinkOrAWithDisable)`
  ${({ $ignoreActive, theme }) => $ignoreActive !== undefined ? `` : `

    &.active .MuiListItem-button {
      border-left: 5px solid ${theme.palette.primary.dark};
      background-color: transparent;
      cursor: default;
      padding-left: 11px;
    }

    &.active .MuiButtonBase-root {
      display: block;
    }

  `}
`

const FillSpace = styled.div`
  flex: 1;
`

const Copyright = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 20px 0;
`

const SmallLinksLine = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0;
`

const SmallNavLink = styled(NavLinkOrAWithDisable)`
  margin: 0 10px;
  user-select: none;
`

const ResetButtonContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

const LogoImg = styled.img`
  height: ${({ $small }) => $small ? 30 : 40}px;
  vertical-align: top;
  margin: 18px;
  align-self: flex-start;
  user-select: none;
`

// const MyLogoImg = styled.img`
//   height: 30px;
//   vertical-align: middle;
//   margin: -5px -7px -4px;
//   user-select: none;
// `

const StyledList = styled(List)`
  position: relative;
  padding: 12px 0;
  z-index: 2;
  border-right: 4px solid ${({ theme }) => theme.palette.grey[400]};
`

const StudyBibleStyledList = styled(StyledList)`
  border-right: 4px solid ${({ theme }) => theme.palette.tertiary.main};
`

const ToolsStyledList = styled(StyledList)`
  z-index: 1;
  border-right: 4px solid ${({ theme }) => theme.palette.secondary.main};
`

const EquipStyledList = styled(StyledList)`
  z-index: 1;
  border-right: 4px solid ${({ theme }) => theme.palette.primary.main};
`

const EquipLogoContainer = styled.div`
  position: relative;
  margin: -6px 0 6px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SectionLogoImg = styled.img`
  height: 30px;
  user-select: none;
`

// const GetMy = styled.span`
//   font-weight: 300;
//   color: ${({ theme }) => theme.palette.tertiary.main};
// `

const GetTools = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.secondary.main};
`

const GetEquip = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.primary.main};
`

const MenuItemOpenInNewIcon = styled(OpenInNewIcon)`
  height: 15px;
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-right: -5px;
`

const NewProjectButton = styled(Button)`
  margin: 10px 15px;
  width: calc(100% - 30px);
`

// const ComingSoon = styled.div`
//   text-align: center;
//   color: ${({ theme }) => theme.palette.grey[500]};
//   padding: 30px 0 40px;
// `

const StyledListItemText = styled(ListItemText)`
  p {
    font-size: 11px;
    margin-top: -3px;
    font-weight: 300;
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const LogoContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $miniHeaderSize }) => $miniHeaderSize ? `flex-start` : `space-between`};
`

const ItemsExcludedInMiniHeader = styled.div`
  align-self: flex-end;
  margin: -20px 15px 10px;
`

const StyledLinkIconButton = styled(LinkIconButton)`
  margin: 0 15px 0 0;

  .MuiSvgIcon-root {
    font-size: 18px;
  }

  ${({ $miniHeaderSize }) => !$miniHeaderSize ? `` : `
    margin: 3px 17px 0 -20px;

    .MuiSvgIcon-root {
      font-size: 15px;
    }
  `}
`

const StyledCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`

const MainMenu = ({
  toggleDrawerOpen,
  setResetApp,
  setSnackbarInfo,
  setUserSelectedTourType,
  ...otherProps
}) => {

  const { assignmentBlock } = useAssignmentBlock()
  const { channelIdInPWA, channelInPWA } = useContext(ChannelIdInPWAContext)
  const { miniHeaderSize } = useAppSize()
  const location = useLocation()

  const user = useContext(LoggedInUserContext)
  // const { hasMyPlan, hasToolsPlan, hasEquipPlan } = user || {}
  const hasMyPlan = false
  const { hasToolsPlan } = user || {}
  const { hasEquipPlan } = user || {}

  const autologin = user ? `?autologin` : ``

  const [ justCopied, setJustCopied ] = useState(false)
  const [ setJustCopiedTimeout ] = useSetTimeout()
  const shareData = useEqualObjsMemo({
    title: i18n("The {{short_name}} Study Bible", { short_name: (channelInPWA || {}).shortName }),
    text: i18n("A Bible app with our church’s sermons next to the passages they teach on!"),
    url: `${document.location.origin}/church/${(channelInPWA || {}).id}`,
  })
  const willShare = !!(channelInPWA && navigator.canShare && navigator.canShare(shareData))

  const [ goCreateProject ] = useGoUpdateProject()

  const { projects: { count=0 }={} } = useDataQuery({
    projectsQuery,
    variables: {
      limit: 3,
    },
    skip: !user,
  })

  const goCreateProjectAndShutDrawer = useCallback(
    () => {
      goCreateProject()
      toggleDrawerOpen(false)
    },
    [ goCreateProject, toggleDrawerOpen ],
  )

  const goCSSBTour = useCallback(
    () => {
      setUserSelectedTourType(`cssb`)
      toggleDrawerOpen(false)
    },
    [ setUserSelectedTourType, toggleDrawerOpen ],
  )

  const shareLink = useCallback(
    async () => {
      if(willShare) {
        try {
          await navigator.share(shareData)
        } catch(e) {}
        return
      }

      copy(shareData.url)
      setJustCopied(true)
      setJustCopiedTimeout(() => {
        setJustCopied(true)
        setJustCopiedTimeout(() => setJustCopied(false), 1500)
      }, 200)

    },
    [ setJustCopied, setJustCopiedTimeout, shareData, willShare ],
  )

  return (
    <Container>

      {assignmentBlock}

      {!assignmentBlock &&
        <LogoContainer>
          <LogoContainer2 $miniHeaderSize={miniHeaderSize} >
            <LogoImg src="/logo.svg" $small={!!channelIdInPWA} />
            {!!channelIdInPWA &&
              <StyledLinkIconButton
                $miniHeaderSize={miniHeaderSize}
                href={process.env.REACT_APP_BASE_URI}
              >
                <LaunchIcon />
              </StyledLinkIconButton>
            }
          </LogoContainer2>
          {miniHeaderSize &&
            <ItemsExcludedInMiniHeader>
              <SecondaryHeaderItems
                showBibleDisplaySettings
                inMenu
                {...otherProps}
              />
            </ItemsExcludedInMiniHeader>
          }
        </LogoContainer>
      }

      <Divider />

      <StudyBibleStyledList>

        {!channelIdInPWA &&
          <>

            {hasMyPlan && <SubscribedInfoIcon to="/your-my-subscription" onClick={toggleDrawerOpen} />}

            {hasMyPlan && <SubscribedIndicator $color="tertiary" />}

            <MenuItemNavLink exact to="/">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                {/* <StyledListItemText>
                  <MyLogoImg src="/my_biblearc_study_bible_1.svg" />
                </StyledListItemText> */}
                <StyledListItemText primary="Read the Bible" />
                {/* {hasMyPlan
                  ? <StyledListItemText primary={i18n("My Study Bible")} />
                  : <StyledListItemText primary={i18n("The Study Bible")} />
                } */}
              </ListItem>
            </MenuItemNavLink>

          </>
        }

        <RecentChurchBibles
          toggleDrawerOpen={toggleDrawerOpen}
          MenuItemNavLink={MenuItemNavLink}
          fixedChurchBible={channelInPWA}
        />

        <MainMenuListeningHistory
          channelIdInPWA={channelIdInPWA}
          toggleDrawerOpen={toggleDrawerOpen}
          setSnackbarInfo={setSnackbarInfo}
        />

        <MainMenuMyBookmarks
          channelIdInPWA={channelIdInPWA}
          toggleDrawerOpen={toggleDrawerOpen}
          setSnackbarInfo={setSnackbarInfo}
        />

        {([ 'ADMIN', 'EDITOR' ].includes((user || {}).adminLevel) || process.env.REACT_APP_STAGE === `development`) &&
          <MenuItemNavLink exact to="/map">
            <ListItem
              button
              onClick={toggleDrawerOpen}
            >
              <StyledListItemText primary={i18n("Bible Map + Timeline")} />
            </ListItem>
          </MenuItemNavLink>
        }

        {/^\/church\/[^/]+$/.test(location.pathname) && !shouldRecommendInstall &&
          <ListItem
            button
            onClick={goCSSBTour}
          >
            <StyledListItemText primary={i18n("Take a Tour")} />
          </ListItem>
        }

        {!!channelInPWA &&
          <ListItem
            button
            onClick={shareLink}
          >
            <StyledListItemText
              primary={
                willShare
                  ? i18n("Share This App")
                  : i18n("Copy Link to This App")
              }
            />
            {justCopied && <StyledCheckIcon />}
          </ListItem>
        }

        {/* {!hasMyPlan &&
          <MenuItemNavLink
            exact
            to="/subscribe"
            $ignoreActive
            onClick={toggleDrawerOpen}
          >
            <ListItem
              button
            >
              <StyledListItemText
                primary={
                  <GetMy>
                    {i18nReact("Get {{thing_to_get}}", {
                      thing_to_get: (
                        <MyLogoImg src="/my_biblearc_study_bible_1.svg" />
                      ),
                    })}
                  </GetMy>
                }
              />
            </ListItem>
          </MenuItemNavLink>
        } */}

      </StudyBibleStyledList>

      {!channelIdInPWA &&
        <>

          <Divider />

          <ToolsStyledList>

            {hasToolsPlan && <SubscribedInfoIcon to="/your-tools-subscription" onClick={toggleDrawerOpen} />}

            {hasToolsPlan && <SubscribedIndicator $color="secondary" />}

            <EquipLogoContainer>

              <SectionLogoImg src="/biblearc_tools_1.svg" />

            </EquipLogoContainer>

            {!hasToolsPlan &&
              <>

                <MenuItemNavLink to="/about-tools">
                  <ListItem
                    button
                    onClick={toggleDrawerOpen}
                  >
                    <StyledListItemText primary={i18n("What is it?")} />
                  </ListItem>
                </MenuItemNavLink>

                {count > 0 &&
                  <MenuItemNavLink to="/projects">
                    <ListItem
                      button
                      onClick={toggleDrawerOpen}
                    >
                      <StyledListItemText primary={i18n("My Projects (View-Only)")} />
                    </ListItem>
                  </MenuItemNavLink>
                }

                {/* <MenuItemNavLink exact to="/project/1">
                  <ListItem
                    button
                    onClick={toggleDrawerOpen}
                  >
                    <StyledListItemText primary="Example Project" />
                  </ListItem>
                </MenuItemNavLink> */}

                <MenuItemNavLink
                  exact
                  to="/subscribe"
                  $ignoreActive
                  onClick={toggleDrawerOpen}
                >
                  <ListItem
                    button
                  >
                    <StyledListItemText
                      primary={
                        <GetTools>
                          {i18n("Get TOOLS")}
                        </GetTools>
                      }
                    />
                  </ListItem>
                </MenuItemNavLink>

              </>
            }

            {hasToolsPlan &&
              <>

                <MenuItemNavLink to="/projects">
                  <ListItem
                    button
                    onClick={toggleDrawerOpen}
                  >
                    <StyledListItemText primary={i18n("All Projects")} />
                  </ListItem>
                </MenuItemNavLink>

                <RecentProjects
                  toggleDrawerOpen={toggleDrawerOpen}
                  MenuItemNavLink={MenuItemNavLink}
                />

                <NewProjectButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  disableElevation
                  onClick={goCreateProjectAndShutDrawer}
                >
                  {i18n("Create a new project")}
                </NewProjectButton>

              </>
            }

          </ToolsStyledList>

          <Divider />

          <EquipStyledList>

            {hasToolsPlan && <SubscribedInfoIcon to="/your-equip-subscription" onClick={toggleDrawerOpen} />}

            {hasEquipPlan && <SubscribedIndicator />}

            <EquipLogoContainer>

              <SectionLogoImg src="/biblearc_equip_1.svg" />

            </EquipLogoContainer>

            {/* <MenuItemNavLink to={`https://equip.biblearc.com/course/interpretation/79707${autologin}`}>
              <ListItem
                button
              >
                <StyledListItemText
                  primary={
                    i18nReact("My Course: {{course_name}}", {
                      course_name: (
                        <ItemName>
                          Interpretation
                        </ItemName>
                      ),
                    })
                  }
                />
                <MenuItemOpenInNewIcon />
              </ListItem>
            </MenuItemNavLink> */}

            {!hasEquipPlan &&
              <>

                <MenuItemNavLink to={`https://equip.biblearc.com${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("About")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

                <MenuItemNavLink to={`https://equip.biblearc.com/learningresources${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("Learning Resources")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

                <MenuItemNavLink
                  exact
                  to="/subscribe"
                  $ignoreActive
                  onClick={toggleDrawerOpen}
                >
                  <ListItem
                    button
                  >
                    <StyledListItemText
                      primary={
                        <GetEquip>
                          {i18n("Get EQUIP")}
                        </GetEquip>
                      }
                    />
                  </ListItem>
                </MenuItemNavLink>

              </>
            }

            {hasEquipPlan &&
              <>

                <MenuItemNavLink to={`https://equip.biblearc.com/all-courses${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("All Courses")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

                <MenuItemNavLink to={`https://equip.biblearc.com/learningresources${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("Learning Resources")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

              </>
            }

          </EquipStyledList>

        </>
      }

      <Divider />

      <StyledList>

        <MenuItemNavLink to="/settings">
          <ListItem
            button
            onClick={toggleDrawerOpen}
          >
            <StyledListItemText primary={i18n("Settings")} />
          </ListItem>
        </MenuItemNavLink>

        {!channelIdInPWA &&
          <MenuItemNavLink exact to="/church-bibles">
            <ListItem
              button
              onClick={toggleDrawerOpen}
            >
              <StyledListItemText
                primary={i18n("Church Study Bibles")}
                secondary={i18n("Look-up your church’s study Bible...")}
              />
            </ListItem>
          </MenuItemNavLink>
        }

        <MenuItemNavLink exact to="/tag">
          <ListItem
            button
            onClick={toggleDrawerOpen}
          >
            <StyledListItemText
              primary={i18n("Tag Hebrew and Greek")}
              secondary={i18n("Help make the original language tools even better.")}
            />
          </ListItem>
        </MenuItemNavLink>

        {!channelIdInPWA &&
          <>

            {/* <MenuItemNavLink to={`https://equip.biblearc.com/give-scholarships${autologin}`}>
              <ListItem
                button
              >
                <StyledListItemText primary={i18n("Give a Scholarship")} />
                <MenuItemOpenInNewIcon />
              </ListItem>
            </MenuItemNavLink> */}

            <MenuItemNavLink exact to="/give-a-gift">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={i18n("Gift Certificates")}
                  secondary={i18n("Give deeper study of Scripture")}
                />
              </ListItem>
            </MenuItemNavLink>

            <MenuItemNavLink exact to="/group-subscriptions">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={i18n("Group Subscriptions")}
                  secondary={i18n("For your family, church, or college")}
                />
              </ListItem>
            </MenuItemNavLink>

            {!hasToolsPlan &&
              <MenuItemNavLink exact to="/whatever-you-can-afford">
                <ListItem
                  button
                  onClick={toggleDrawerOpen}
                >
                  <StyledListItemText
                    primary={i18n("Biblearc TOOLS for Free")}
                    secondary={i18n("For select countries in the developing world")}
                  />
                </ListItem>
              </MenuItemNavLink>
            }

            <MenuItemNavLink exact to="/donate">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={i18n("Give a Donation")}
                  secondary={i18n("Goes toward scholarships and feature additions")}
                />
              </ListItem>
            </MenuItemNavLink>

            {/* <MenuItemNavLink exact to="/faq">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText primary={i18n("FAQ")} />
              </ListItem>
            </MenuItemNavLink> */}

          </>
        }

      </StyledList>

      <Divider />
      <FillSpace />

      <ResetButtonContainer>
        <Button
          variant="contained"
          size="small"
          disableElevation
          onClick={setResetApp}
        >
          {i18n("Reset app")}
        </Button>
      </ResetButtonContainer>

      <SmallLinksLine>

        <SmallNavLink to={`https://equip.biblearc.com/contact`}>
          {channelIdInPWA ? i18n("Report a Bug") : i18n("Contact")}
        </SmallNavLink>

        <SmallNavLink exact to="/terms" onClick={toggleDrawerOpen}>
          {i18n("Terms")}
        </SmallNavLink>

        <SmallNavLink exact to="/privacy" onClick={toggleDrawerOpen}>
          {i18n("Privacy")}
        </SmallNavLink>

      </SmallLinksLine>

      <Copyright>
        {`© ${new Date().getFullYear()} Bethlehem College & Seminary`}
      </Copyright>

    </Container>
  )
}

export default memo(MainMenu)