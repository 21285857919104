import { memo, useRef, useEffect } from 'react'
import styled from 'styled-components'

import useRefState from '../../hooks/useRefState'
import useEffectAsync from '../../hooks/useEffectAsync'
import { cloneObj, equalObjs } from '../../utils/misc'

import BaseHighlight from './BaseHighlight'
import NormalHighlight from './NormalHighlight'
import TallHighlight from './TallHighlight'
import NormalUnderline from './NormalUnderline'
import ZigZagUnderline from './ZigZagUnderline'
import DashUnderline from './DashUnderline'
import DottedUnderline from './DottedUnderline'
import ArrowHighlightLeft from './ArrowHighlightLeft'
import ArrowHighlightRight from './ArrowHighlightRight'
import ArrowHighlightDouble from './ArrowHighlightDouble'
import Circle from './Circle'
import Replacement from './Replacement'

const MIN_ARROW_PROPORTION = {
  LEFT: 1.1,
  RIGHT: 1.1,
  DOUBLE: 1.5,
}

const Container = styled.div`
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
  position: relative;

  @media print {
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

`

const TextContentMarkupContainer = ({
  isRTL,
  markup,
  onLoad,
  children,
  ...otherProps
}) => {

  // NOTE: If I find that there are unobserved changes to the layout of words, I will probably
  // need to make all words inline-block and then put a ResizeObserver on all of them.
  // (ResizeObserver does not work with display:inline.)

  const ref = useRef()
  const [ markupLines, setMarkupLines, getMarkupLines ] = useRefState([])

  // when DOM within the container changes, check to see if any elements need updating
  useEffect(
    () => {

      if((markup || []).length === 0) {
        if(getMarkupLines()) setMarkupLines([])
        return
      }

      const containerEl = ref.current

      const determineMarkupLines = () => {
        if(!ref.current) return

        const getBoundingClientRectAdjustmentFactor = 5.5 / document.getElementById(`biblearc-bounding-client-rect-tester`).getBoundingClientRect().height
        const { top: containerTop, left: containerLeft, right: containerToRightSide } = ref.current.getBoundingClientRect()
        const windowInnerWidth = window.innerWidth
        const containerRight = window.innerWidth - containerToRightSide

        // const spanEls = containerEl.querySelectorAll(`[data-word-loc], .TextContent-tag-v, .TextContent-tag-x, .TextContent-tag-f`)
        // const spanEls = [ ...containerEl.querySelectorAll(`span`) ]
        const spanElInfos = (
          [ ...containerEl.querySelectorAll(`span`) ]
            .map(spanEl => {

              if(
                (spanEl.firstChild || {}).nodeType !== Node.TEXT_NODE
                && !spanEl.matches(`[data-word-loc]`)
              ) return null
              if(spanEl.matches(`.TextContent-verse`)) return null

              let [ loc, wordNumberInVerse ] = (spanEl.getAttribute(`data-word-loc`) || ``).split(':')
              wordNumberInVerse = parseInt(wordNumberInVerse, 10)
              let { top, left, right: toRightSide, width, height } = spanEl.getBoundingClientRect()
              const adjustedHeight = height * getBoundingClientRectAdjustmentFactor
              let right = (windowInnerWidth - toRightSide)
              top -= containerTop
              top += (height - adjustedHeight) / 2
              height = adjustedHeight
              left -= containerLeft
              right -= containerRight
              const sideValue = isRTL ? right : left
              const sideKey = isRTL ? `right` : `left`
              const classList = [ ...spanEl.classList ]

              return {
                loc,
                wordNumberInVerse,
                top,
                width,
                height,
                sideValue,
                sideKey,
                classList,
              }
            })
            .filter(Boolean)
        )

        const newMarkupLines = []

        // // USE THIS FOR TESTING COLORS
        // const markup = []
        // ;[
        //   [`RED`,`01001001`],
        //   [`BLUE`,`01001002`],
        //   [`YELLOW`,`01001003`],
        //   [`ORANGE`,`01001004`],
        //   [`GREEN`,`01001005`],
        //   [`PURPLE`,`01001006`],
        //   [`PINK`,`01001007`],
        //   [`LIGHT-BLUE`,`01001008`],
        // ].forEach(([ color, loc ]) => {
        //   markup.push(
        //     {
        //       start: {
        //         loc,
        //         wordNumberInVerse: 1,
        //       },
        //       end: {
        //         loc,
        //         wordNumberInVerse: 2,
        //       },
        //       type: "BASE-HIGHLIGHT",
        //       color,
        //     },
        //     {
        //       start: {
        //         loc,
        //         wordNumberInVerse: 4,
        //       },
        //       end: {
        //         loc,
        //         wordNumberInVerse: 4,
        //       },
        //       type: "NORMAL-HIGHLIGHT",
        //       color,
        //     },
        //     {
        //       start: {
        //         loc,
        //         wordNumberInVerse: 6,
        //       },
        //       end: {
        //         loc,
        //         wordNumberInVerse: 6,
        //       },
        //       type: "TALL-HIGHLIGHT",
        //       color,
        //     },
        //     {
        //       start: {
        //         loc,
        //         wordNumberInVerse: 7,
        //       },
        //       end: {
        //         loc,
        //         wordNumberInVerse: 7,
        //       },
        //       type: "NORMAL-UNDERLINE",
        //       color,
        //     },
        //     {
        //       start: {
        //         loc,
        //         wordNumberInVerse: 8,
        //       },
        //       end: {
        //         loc,
        //         wordNumberInVerse: 8,
        //       },
        //       type: "DOTTED-UNDERLINE",
        //       color,
        //     },
        //     {
        //       start: {
        //         loc,
        //         wordNumberInVerse: 9,
        //       },
        //       end: {
        //         loc,
        //         wordNumberInVerse: 9,
        //       },
        //       type: "ZIG-ZAG-UNDERLINE",
        //       color,
        //     },
        //   )
        // })

        // // USE THIS FOR TESTING UNDERLINES
        // const loc = `01001001`
        // const markup = [
        //   {
        //     start: {
        //       loc,
        //       wordNumberInVerse: 1,
        //     },
        //     end: {
        //       loc,
        //       wordNumberInVerse: 7,
        //     },
        //     type: "NORMAL-UNDERLINE",
        //     color: `LIGHT-BLUE`,
        //   },
        //   {
        //     start: {
        //       loc,
        //       wordNumberInVerse: 2,
        //     },
        //     end: {
        //       loc,
        //       wordNumberInVerse: 4,
        //     },
        //     type: "DOTTED-UNDERLINE",
        //     color: `RED`,
        //   },
        //   {
        //     start: {
        //       loc,
        //       wordNumberInVerse: 3,
        //     },
        //     end: {
        //       loc,
        //       wordNumberInVerse: 9,
        //     },
        //     type: "ZIG-ZAG-UNDERLINE",
        //     color: `BLUE`,
        //   },
        //   {
        //     start: {
        //       loc: `01001002`,
        //       wordNumberInVerse: 19,
        //     },
        //     end: {
        //       loc: `01001002`,
        //       wordNumberInVerse: 21,
        //     },
        //     type: "NORMAL-UNDERLINE",
        //     color: `BLUE`,
        //   },
        //   {
        //     start: {
        //       loc: `01001003`,
        //       wordNumberInVerse: 4,
        //     },
        //     end: {
        //       loc: `01001003`,
        //       wordNumberInVerse: 7,
        //     },
        //     type: "DOTTED-UNDERLINE",
        //     color: `RED`,
        //   },
        //   {
        //     start: {
        //       loc: `01001003`,
        //       wordNumberInVerse: 10,
        //     },
        //     end: {
        //       loc: `01001003`,
        //       wordNumberInVerse: 11,
        //     },
        //     type: "ZIG-ZAG-UNDERLINE",
        //     color: `YELLOW`,
        //   },
        // ]

        // for each style, get the data for the lines
        markup.forEach(({ start, end, wordPart, type: markupType, color, direction, replacementText }) => {

          if(!(start || {}).loc || !(end || {}).loc) return

          start = cloneObj(start)
          end = cloneObj(end)
          start.wordNumberInVerse = start.wordNumberInVerse || 1
          end.wordNumberInVerse = end.wordNumberInVerse || Math.max(1, ...spanElInfos.filter(({ loc }) => loc === end.loc).map(({ wordNumberInVerse }) => wordNumberInVerse))

          const line = {
            markupType,
            details: {
              color,
            },
          }

          let lineCoords = null
          let lineNum = 0
          let latestLoc
          let includesStart = true

          for(let idx=0; idx<spanElInfos.length; idx++) {
            const {
              loc,
              wordNumberInVerse,
              top,
              width,
              height,
              sideValue,
              sideKey,
              classList,
            } = spanElInfos[idx]

            latestLoc = loc || latestLoc

            const isStart = ({ loc: l, wordNumberInVerse: w }={ loc, wordNumberInVerse }) => (
              (
                l === start.loc
                && parseInt(w, 10) === start.wordNumberInVerse
              )
              || (
                // this markup begins before the current end of the passage
                l > start.loc
                && l <= end.loc
              )
            )

            const startLine = () => {  // eslint-disable-line no-loop-func
              lineCoords = {
                top,
                [sideKey]: sideValue,
                width,
                height,
              }
            }

            const pushLine = (includesEnd=false) => {  // eslint-disable-line no-loop-func
              if(!lineCoords) return
              const minProportion = MIN_ARROW_PROPORTION[markupType.replace(/^ARROW-HIGHLIGHT-/, '')]
              if(lineCoords.width < lineCoords.height * minProportion) {
                const extraWidth = lineCoords.height * minProportion - lineCoords.width
                lineCoords.width += extraWidth
                lineCoords[sideKey] -= extraWidth / 2
              }
              newMarkupLines.push({
                ...line,
                id: JSON.stringify({
                  start,
                  end,
                  wordPart,
                  markupType,
                  color,
                  lineNum: ++lineNum,
                }),
                details: {
                  ...line.details,
                  ...lineCoords,
                  includesStart,
                  includesEnd,
                  isRTL,
                },
              })
              includesStart = false
            }

            if(lineCoords) {
              // we have already hit the start
              if(loc > end.loc || (loc === end.loc && wordNumberInVerse > end.wordNumberInVerse)) {
                // we are past the end (shouldn't happen)
                pushLine(true)
                break
              }
              if(top > lineCoords.top + lineCoords.height/2) {
                // need to start a new line
                pushLine()
                startLine()
              } else if(Math.abs(top - lineCoords.top) < 1 && height > lineCoords.height * 2 - 1) {
                // this is an inline element that wraps the line
                lineCoords.width = (sideValue + width) - lineCoords[sideKey]
                pushLine()
                lineCoords = {
                  top: top + height - lineCoords.height,  // assume the next line to be the same height
                  [sideKey]: sideValue,
                  width: 0,  // this will be build onto with the next word
                  height: lineCoords.height,  // assume the next line to be the same height
                }
              } else {
                // add to current line
                lineCoords.width = (sideValue + width) - lineCoords[sideKey]
              }
            } else if(loc > start.loc || (loc === start.loc && wordNumberInVerse > start.wordNumberInVerse)) {
              // we are past the start (shouldn't happen)
              break
            } else if(isStart()) {
              // we are at the start
              startLine()
            } else if(classList.includes(`TextContent-tag-v`)) {
              const nextWordElInfo = spanElInfos.slice(idx+1).find(({ loc }) => loc)
              if(isStart(nextWordElInfo)) {
                let endOrNextLoc = end.loc
                if(endOrNextLoc === start.loc) {
                  const endIdx = spanElInfos.slice(idx+1).findIndex(({ loc, wordNumberInVerse }) => (loc === end.loc && wordNumberInVerse === end.wordNumberInVerse)) + idx + 1
                  const wordElInfoAfterEnd = spanElInfos.slice(endIdx+1).find(({ loc }) => loc)
                  endOrNextLoc = (wordElInfoAfterEnd || {}).loc
                }
                if(endOrNextLoc > start.loc) {
                  startLine()
                }
              }
            }

            if(loc === end.loc && wordNumberInVerse === end.wordNumberInVerse) {
              // we hit the end
              pushLine(true)
              break
            }

            if(
              idx === spanElInfos.length - 1
              && latestLoc >= start.loc
              && latestLoc <= end.loc
            ) {
              // this markup goes beyond the current end of the passage
              pushLine(true)
            }
          }

        })

        if(!equalObjs(newMarkupLines, getMarkupLines())) {
          setMarkupLines(newMarkupLines)
        }

      }

      const resizeObserver = new ResizeObserver(determineMarkupLines)
      const mutationObserver = new MutationObserver(determineMarkupLines)

      resizeObserver.observe(containerEl)
      mutationObserver.observe(
        containerEl,
        {
          subtree: true,
          childList: true,
          attributes: true,
          characterData: true,
        },
      )

      return () => {
        resizeObserver.unobserve(containerEl)
        mutationObserver.disconnect()
      }
    },
    [ markup, isRTL ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffectAsync(
    () => {
      onLoad && setTimeout(onLoad)  // setTimeout allows it to render
    },
    []
  )

  return (
    <Container
      {...otherProps}
      ref={ref}
      $isRTL={isRTL}
    >

      {children}

      {markupLines.map(({ id, markupType, details }) => {

        const Component = {
          "BASE-HIGHLIGHT": BaseHighlight,
          "NORMAL-HIGHLIGHT": NormalHighlight,
          "TALL-HIGHLIGHT": TallHighlight,
          "NORMAL-UNDERLINE": NormalUnderline,
          "ZIG-ZAG-UNDERLINE": ZigZagUnderline,
          "DOTTED-UNDERLINE": DottedUnderline,
          "DASH-UNDERLINE": DashUnderline,
          "ARROW-HIGHLIGHT-LEFT": ArrowHighlightLeft,
          "ARROW-HIGHLIGHT-RIGHT": ArrowHighlightRight,
          "ARROW-HIGHLIGHT-DOUBLE": ArrowHighlightDouble,
          "CIRCLE": Circle,
          "REPLACEMENT": Replacement,
        }[markupType] || NormalHighlight

        return (
          <Component
            key={id}
            className="TextContentMarkupContainer-Component"
            data-color={details.color}
            {...details}
          />
        )

      })}
    </Container>
  )
}

export default memo(TextContentMarkupContainer)



// setMarkupLines([
//   {
//     id: `test1`,
//     markupType: `NORMAL-HIGHLIGHT`,
//     details: {
//       top: 30,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//     },
//   },
//   {
//     id: `test2`,
//     markupType: `BASE-HIGHLIGHT`,
//     details: {
//       top: 60,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `RED`,
//     },
//   },
//   {
//     id: `test3`,
//     markupType: `TALL-HIGHLIGHT`,
//     details: {
//       top: 90,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//     },
//   },
//   {
//     id: `test4`,
//     markupType: `NORMAL-UNDERLINE`,
//     details: {
//       top: 120,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//     },
//   },
//   {
//     id: `test5`,
//     markupType: `ZIG-ZAG-UNDERLINE`,
//     details: {
//       top: 150,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//     },
//   },
//   {
//     id: `test6`,
//     markupType: `DOTTED-UNDERLINE`,
//     details: {
//       top: 180,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//     },
//   },
//   {
//     id: `test7`,
//     markupType: `ARROW-HIGHLIGHT`,
//     details: {
//       top: 210,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//       startType: `point`,
//       endType: `tail`,

//     },
//   },
//   {
//     id: `test7b`,
//     markupType: `ARROW-HIGHLIGHT`,
//     details: {
//       top: 210,
//       left: 400,
//       width: 100,
//       height: 20,
//       color: `BLUE`,
//       startType: `point`,
//     },
//   },
//   {
//     id: `test7c`,
//     markupType: `ARROW-HIGHLIGHT`,
//     details: {
//       top: 210,
//       left: 550,
//       width: 100,
//       height: 20,
//       color: `BLUE`,
//       startType: `tail`,
//     },
//   },
//   {
//     id: `test8`,
//     markupType: `CIRCLE`,
//     details: {
//       top: 240,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//       startType: `closed`,
//       endType: `closed`,
//     },
//   },
//   {
//     id: `test9`,
//     markupType: `REPLACEMENT`,
//     details: {
//       top: 270,
//       left: 100,
//       width: 200,
//       height: 20,
//       color: `BLUE`,
//       replacementText: `blah blah blah`,
//     },
//   },
// ])