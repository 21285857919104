import { memo, useCallback, useContext, useRef } from 'react'
import { useLocation } from "react-router-dom"
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import useDataQuery from '../../../hooks/useDataQuery'
import useRefState from '../../../hooks/useRefState'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import { isPWA } from '../../../utils/misc'
import { LoggedInUserContext } from '../../../context/LoggedInUser'
import { getChannelVars } from '../channel/Channel'

import Header from "../../common/Header"
import FadedLoading from '../../common/FadedLoading'
import FAQFab from '../../common/FAQFab'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'
import EditChannelDetailDialog from './EditChannelDetailDialog'
import ChannelNotFound from '../channel/ChannelNotFound'
import PayForCSSBDialog from '../create-a-church-bible/PayForCSSBDialog'
import SignInButton from '../../common/SignInButton'
import LinkButton from '../../common/LinkButton'

import channelQuery from '../../../graphql/queries/channel'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px max(60vh, 300px);
  background-color: white;
  position: relative;
`

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 300;
  line-height: 48px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  margin: 20px 0 0;
`

const TitleContent = styled.div`
  white-space: nowrap;
`

const Name = styled.div`
  display: inline-block;
  font-weight: 500;

`

const Image = styled.img`
  width: 29px;
  height: 29px;
  margin: 9.5px 8px 9.5px 4px;
  object-fit: cover;
  border-radius: 5px;
  position: relative;

  &::after {  
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    content: "";
  }
`

const LeftImage = styled.img`
  width: 29px;
  height: 29px;
  margin: 9.5px 8px 9.5px 4px;
  object-fit: cover;
  border-radius: 5px;
  position: relative;

  &::after {  
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    content: "";
  }
`

const URLContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px 0 40px;
  font-size: 15px;
`

const AssetsForPromo = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 15px;
  z-index: 1;
  position: relative;
`

const IconAndQR = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 10px;
`

const IconOrQR = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AppIcon = styled.img`
  width: 130px;
  height: 130px;
`

const QRCode = styled.img`
  width: 166px;
  height: 166px;
  margin: -18px;
`

const DownloadLink = styled.a`
  font-size: 14px;
  margin: 3px 0;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`

const StatsAndComments = styled.div`

`

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Host = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 100px;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const NumSermons = styled.div`
`

const Stat = styled(Host)`
  width: 80px;
  height: 80px;
  font-size: 11px;
  line-height: .9;
`

const StatText = styled.div`
  margin: 3px 10px 0;
  height: 10px;
`

const Spacer = styled.div`
  flex: 1;
  min-height: 0;
`

const HostURL = styled.div`
  margin-top: 5px;
  width: 110px;
  font-size: 9px;
  line-height: 1.1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1.2;
`

const HostNum = styled.div`
  font-size: 32px;
  line-height: .8;
  font-weight: bold;
`

const Num = styled.div`
  font-size: 22px;
  line-height: .8;
  font-weight: bold;
`

const Hosts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const UsageStats = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
`

const LatestComments = styled.div`

`

const Details = styled.div`

`

const Heading = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 40px 0 20px;
`

const StyledTextField = styled(TextField)`
  width: 590px;
  max-width: calc(100vw - 30px);
  
  .MuiInputBase-input {
    color: black;
  }
`

const TextFieldContainer = styled.div`
  margin: 15px 0;
  position: relative;
`

const EditIconButton = styled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgb(255 255 255/.7);
  padding: 8px;

  &:hover {
    background: rgb(220 220 220/.7);
  }

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const CompletePurchaseSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.tertiary.main}55;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.tertiary.main}33;
  padding: 15px 22px;
  margin: 20px 0 0;
`

const DaysLeft = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.tertiary.main};
  font-weight: bold;
`

const StyledButton = styled(Button)`
  background-color: black;
  color: white;
  margin: 10px 0 7px;

  &:hover {
    background-color: rgb(0 0 0/.7);
  }
`

const BackLinkButton = styled(LinkButton)`
  align-self: flex-start;
  margin: 5px -5px 0;
`

const ChannelAdmin = ({ ...props }) => {

  const location = useLocation()
  const id = location.pathname.split('/')[2]

  const loggedInUser = useContext(LoggedInUserContext)

  const { channel, loading } = useDataQuery({
    channelQuery,
    variables: {
      id,
    },
    fetchPolicy: `network-only`,    
    sticky: true,
  })

  const {
    name,
    shortName,
    location: channelLocation,
    description,
    updatedAt,
    channelUrls,
    stats,
    purchasedAt,
  } = channel || {}

  const { iAmAnAdmin, numDaysLeft, trialExpired } = getChannelVars({ channel, loggedInUser })

  const statTotals = {}
  Object.values((stats || {}).channelUrls || {}).forEach(channelUrlStats => {
    for(let key in channelUrlStats) {
      statTotals[key] = (statTotals[key] || 0) + channelUrlStats[key]
    }
  })

  const [ payDialogOpen, togglePayDialogOpen ] = useSimpleToggle(false)
  const [ editChannelDialogInfo, setEditChannelDialogInfo, getEditChannelDialogInfo ] = useRefState({})
  const containerRef = useRef()

  const iconSrc = updatedAt ? `${process.env.REACT_APP_ASSETS_URI}${id}-favicon_192-${updatedAt}.png` : null
  const imgURL = updatedAt ? `${process.env.REACT_APP_ASSETS_URI}${id}-favicon_1024-${updatedAt}.png` : null
  const qrCodeURL = `${process.env.REACT_APP_ASSETS_URI}${id}-qrcode.png`

  const commonProps = {
    variant: "outlined",
    size: "small",
    fullWidth: true,
    disabled: true,
  }

  const onCancel = useCallback(
    () => {
      setEditChannelDialogInfo({
        ...getEditChannelDialogInfo(),
        open: false,
      })
    },
    [ setEditChannelDialogInfo, getEditChannelDialogInfo ],
  )

  const onEdit = useCallback(
    ({ target }) => {

      const detail = target.closest('[data-detail]').getAttribute('data-detail')

      setEditChannelDialogInfo({
        detail,
        currentValue: channel[detail],
        multiline: detail === `description`,
        open: true,
      })

    },
    [ setEditChannelDialogInfo, channel ],
  )

  if(!channel && !loading && !isPWA) {
    return (
      <ChannelNotFound
        {...props}
      />
    )
  }

  if(!loggedInUser) {
    return (
      <ChannelNotFound
        message={i18n("This is a restricted page.")}
        buttons={<SignInButton />}
        {...props}
      />
    )
  }

  if(!loading && !iAmAnAdmin) {
    return (
      <ChannelNotFound
        message={i18n("You are not an admin of this Study Bible.")}
        {...props}
      />
    )
  }

  return (
    <>

      <Header
        {...props}
        leftExtraComponent={
          <LeftImage
            src={iconSrc}
            className="dark-mode-exempt"
          />
        }
      />

      <Container ref={containerRef}>

        <BackLinkButton
          to={`/church/${id}`}
          startIcon={<ArrowBackIcon />}
          color="basic"
        >
          {i18n("Back to the Bible")}
        </BackLinkButton>

        <Content>

          {!purchasedAt &&
            <CompletePurchaseSection>
              <DaysLeft>
                {trialExpired
                  ? i18n("Trial expired. This Study Bible is disabled until setup is complete.")
                  : (
                    i18n("You have {{num_days}} left on your trial.", {
                      num_days: (
                        numDaysLeft === 1
                          ? i18n("a day")
                          : i18n("{{num}} days", { num: numDaysLeft })
                      ),
                    })
                  )
                }
              </DaysLeft>
              <StyledButton
                variant="contained"
                disableElevation
                onClick={togglePayDialogOpen}
              >
                {i18n("Pay + Complete Setup")}
              </StyledButton>
              <PayForCSSBDialog
                channelId={id}
                open={payDialogOpen}
                onCancel={togglePayDialogOpen}
              />
            </CompletePurchaseSection>
          }

          <Title>
            <Image
              src={iconSrc}
              className="dark-mode-exempt"
            />
            <TitleContent>
              {i18nReact("The {{short_name}} Study Bible", {
                short_name: (
                  <Name>
                    {shortName}
                  </Name>
                ),
              })}
            </TitleContent>
          </Title>

          <URLContainer>
            <NavLinkOrAWithDisable to={`/church/${id}`}>
              {`biblearc.com/church/${id}`}
            </NavLinkOrAWithDisable>
          </URLContainer>

          <AssetsForPromo>
            {i18n("Assets for promoting to church members:")}
          </AssetsForPromo>

          <IconAndQR>
            <IconOrQR>
              <AppIcon
                src={imgURL}
                className="dark-mode-exempt"
              />
              <Links>
                <DownloadLink href={imgURL} download="app_icon">
                  {i18n("Download")}
                </DownloadLink>
                <DownloadLink
                  href="#"
                  onClick={onEdit}
                  data-detail="imageEditInfo"
                >
                  {i18n("Edit")}
                </DownloadLink>
              </Links>
            </IconOrQR>
            <IconOrQR>
              <QRCode src={qrCodeURL} />
              <DownloadLink href={qrCodeURL} download="qr_code">
                {i18n("Download")}
              </DownloadLink>
            </IconOrQR>
          </IconAndQR>

          <StatsAndComments>
            <Heading>{i18n("Stats")}</Heading>
            <Stats>
              <Hosts>
                {(channelUrls || []).map(channelUrl => (
                  <Host key={channelUrl}>
                    <Spacer />
                    {(((stats || {}).channelUrls || {})[channelUrl] || {}).numSermons === null &&
                      <Host>
                        <Spacer />
                        <NumSermons>
                          {i18n("syncing...")}
                        </NumSermons>
                        <HostURL>
                          {channelUrl}
                        </HostURL>
                      </Host>
                    }
                    {(((stats || {}).channelUrls || {})[channelUrl] || {}).numSermons !== null &&
                      <Host>
                        <Spacer />
                        <NumSermons>
                          <HostNum>
                            {(((stats || {}).channelUrls || {})[channelUrl] || {}).numSermons}
                          </HostNum>
                          {i18n("sermons")}
                        </NumSermons>
                        <HostURL>
                          {channelUrl}
                        </HostURL>
                      </Host>
                    }
                  </Host>
                ))}
              </Hosts>
              <UsageStats>
                <Stat>
                  <Spacer />
                  <Num>
                    {statTotals.numUsersInLastMonth}
                  </Num>
                  <StatText>
                    {i18n("active users")}
                  </StatText>
                  <Spacer />
                </Stat>
                <Stat>
                  <Spacer />
                  <Num>
                    {statTotals.numChapViewsInLastWeek}
                  </Num>
                  <StatText>
                    {i18n("views in last week")}
                  </StatText>
                  <Spacer />
                </Stat>
                <Stat>
                  <Spacer />
                  <Num>
                    {statTotals.numBookmarks}
                  </Num>
                  <StatText>
                    {i18n("sermon bookmarks")}
                  </StatText>
                  <Spacer />
                </Stat>
                <Stat>
                  <Spacer />
                  <Num>
                    {statTotals.numComments}
                  </Num>
                  <StatText>
                    {i18n("comments")}
                  </StatText>
                  <Spacer />
                </Stat>
              </UsageStats>
            </Stats>
            <LatestComments>
            </LatestComments>
          </StatsAndComments>

          <Details>

            <Heading>
              {i18n("Church Details")}
            </Heading>

            <TextFieldContainer>
              <StyledTextField
                {...commonProps}
                value={name}
                label={i18n("Name")}
              />
              <EditIconButton
                onClick={onEdit}
                data-detail="name"
              >
                <EditIcon />
              </EditIconButton>
            </TextFieldContainer>

            <TextFieldContainer>
              <StyledTextField
                {...commonProps}
                value={shortName}
                label={i18n("Short Name")}
              />
              <EditIconButton
                onClick={onEdit}
                data-detail="shortName"
              >
                <EditIcon />
              </EditIconButton>
            </TextFieldContainer>

            <TextFieldContainer>
              <StyledTextField
                {...commonProps}
                value={channelLocation}
                label={i18n("Location")}
              />
              <EditIconButton
                onClick={onEdit}
                data-detail="location"
              >
                <EditIcon />
              </EditIconButton>
            </TextFieldContainer>

            <TextFieldContainer>
              <StyledTextField
                {...commonProps}
                value={description}
                label={i18n("About")}
                multiline
              />
              <EditIconButton
                onClick={onEdit}
                data-detail="description"
              >
                <EditIcon />
              </EditIconButton>
            </TextFieldContainer>

          </Details>

        </Content>

      </Container>

      <FAQFab to={`/church/${id}/faq`} />

      {loading && <FadedLoading />}

      <EditChannelDetailDialog
        {...editChannelDialogInfo}
        channelId={id}
        onCancel={onCancel}
        shortName={shortName}
      />

    </>
  )
}

export default memo(ChannelAdmin)