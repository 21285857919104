import { memo, useMemo } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import NavLinkOrAWithDisable from './NavLinkOrAWithDisable'

const Container = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 1.3;

  p {
    margin: .5em 0;

    &:first-child {
      margin-top: 0
    }

    &:last-child {
      margin-bottom: 0
    }
  }

  ol {
    margin-block-start: .5em;
    margin-block-end: .5em;
    padding-inline-start: 25px;
  }

  li {
    margin: 0.2em 0;
  }

  pre {
    margin: 0.75em 15px;
    font-family: inherit;
    font-size: .87em;
    white-space: pre-wrap;
  }

  code {
    font-family: inherit;
  }

  img {
    max-width: 100%;
  }

  blockquote {
    margin: .5em 0;
    padding-left: 10px;
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    color: ${({ theme }) => theme.palette.grey[500]};

    &:first-child {
      margin-top: 0
    }

    &:last-child {
      margin-bottom: 0
    }
  }

`

const MarkdownContainer = ({
  onClickLink,
  children,
  ...otherProps
}) => {

  const components = useMemo(
    () => ({
      a: ({ children, href }) => (
        <NavLinkOrAWithDisable
          children={children}
          to={href}
          keepLinkStyle={true}
          onClick={onClickLink}
        />
      ),
      img: ({ className, node, ...otherProps }) => (
        <img
          alt=""
          {...otherProps}
          className={`${className || ``} dark-mode-exempt`}
        />
      ),
    }),
    [ onClickLink ]
  )

  return (
    <Container {...otherProps}>
      <ReactMarkdown
        components={components}
      >
        {children}
      </ReactMarkdown>
    </Container>
  )
}

export default memo(MarkdownContainer)