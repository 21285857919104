import { useCallback, useRef } from 'react'
import useSetTimeout from './useSetTimeout';
import { preventDefaultEvent, usingModifierKey, vibrate } from '../utils/misc';
import useInstanceValue from './useInstanceValue';

const useLongPress = ({
  onLongPress,
  onClick,
  skipPreventDefault,
  delay=300,
  disableModifierKeyLongPress,
  doNotVibrate,
}) => {

  const [ setLongPressTimeout, clearLongPressTimeout ] = useSetTimeout()
  const getOnLongPress = useInstanceValue(onLongPress)
  const getOnClick = useInstanceValue(onClick)
  const doNotStart = useRef(false)

  const onStart = useCallback(
    event => {
      if(doNotStart.current) return
      if(!skipPreventDefault) preventDefaultEvent(event)

      if(!disableModifierKeyLongPress && usingModifierKey(event)) {
        getOnLongPress() && getOnLongPress()()
        return
      }

      let isClick = true
      const { clientX, clientY } = event.touches ? event.touches[0] : event

      const onDone = event => {
        if(event && !skipPreventDefault) preventDefaultEvent(event)

        clearLongPressTimeout()
        isClick && getOnClick() && getOnClick()()

        document.body.removeEventListener('mousemove', onMove)
        document.body.removeEventListener('mouseup', onDone)
        document.body.removeEventListener('mouseleave', onDone)
        document.body.removeEventListener('touchmove', onMove)
        document.body.removeEventListener('touchend', onDone)
        document.body.removeEventListener('touchcancel', onDone)

        doNotStart.current = false
      }

      const onMove = event => {
        const { clientX: moveClientX, clientY: moveClientY } = event.touches ? event.touches[0] : event
        if(Math.abs(moveClientX - clientX) + Math.abs(moveClientY - clientY) < 7) return
        isClick = false
        clearLongPressTimeout()
      }

      document.body.addEventListener('mousemove', onMove)
      document.body.addEventListener('mouseup', onDone)
      document.body.addEventListener('mouseleave', onDone)
      document.body.addEventListener('touchmove', onMove)
      document.body.addEventListener('touchend', onDone)
      document.body.addEventListener('touchcancel', onDone)

      setLongPressTimeout(() => {
        isClick = false
        !doNotVibrate && vibrate()
        getOnLongPress() && getOnLongPress()()
      }, delay)

      doNotStart.current = true

    },
    [ skipPreventDefault, disableModifierKeyLongPress, delay, setLongPressTimeout, clearLongPressTimeout, getOnClick, getOnLongPress, doNotVibrate ]
  )

  return {
    onMouseDown: onStart,
    onTouchStart: onStart,
    onClick: skipPreventDefault ? null : preventDefaultEvent,
  }
}

export default useLongPress