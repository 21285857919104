import { useCallback } from 'react'
import { useMeasure } from 'react-use'

import useRefState from './useRefState'
import useSetTimeout from './useSetTimeout'
import useStickyRefState from './useStickyRefState'
import useDoubleClickCallback from './useDoubleClickCallback'
import { getBoundedValue } from '../utils/misc'

export const MAP_INTRINSIC_WIDTH = 248
export const MAP_INTRINSIC_HEIGHT = 150
const MAX_MAP_SCALE = 100
const TRANSITION_MS = 0

const useDragMap = () => {

  const [ ref, { width, height, left, top } ] = useMeasure()

  const getTransformValues = useCallback(
    mapTransformValues => {
      const values = {
        scale: 1,
        translateX: 0,
        translateY: 0,
        ...(mapTransformValues || {}),
      }
      return values
    },
    [],
  )

  const [ mapTransformValues, setMapTransformValues, getMapTransformValues ] = useStickyRefState({ id: `useDragMap:mapTransformValues`, defaultValue: getTransformValues() })
  const [ moving, setMoving, getMoving ] = useRefState(false)
  let { scale, translateX, translateY } = getTransformValues(mapTransformValues)

  const mapProportion = MAP_INTRINSIC_WIDTH / MAP_INTRINSIC_HEIGHT
  const proportion = width ? (width / height) : 1
  const mapIsWider = mapProportion > proportion
  const baseMapWidth = mapIsWider ? (mapProportion * height) : width
  const baseMapHeight = !mapIsWider ? (width / mapProportion) : height

  scale = getBoundedValue(scale, { min: 1, max: MAX_MAP_SCALE })

  const maxMapTranslateX = (width - baseMapWidth * scale)
  const maxMapTranslateY = (height - baseMapHeight * scale)
  translateX = getBoundedValue(translateX, { min: maxMapTranslateX, max: 0 })
  translateY = getBoundedValue(translateY, { min: maxMapTranslateY, max: 0 })

  const [ beyondBoundary, setBeyondBoundary, getBeyondBoundary ] = useRefState(``)
  const [ setBeyondBoundaryTimeout ] = useSetTimeout()

  const [ inTransition, setInTransition ] = useRefState(false)
  const [ setTransitionTimeout ] = useSetTimeout()

  // const [ transitionStyleAddOn, setTransitionStyleAddOn, getTransitionStyleAddOn ] = useRefState()
  // const [ setTransitionTimeout ] = useSetTimeout()

  // const doTransitionEffect = useCallback(
  //   ({ style, ms=300, reverseEffect, skipFinish, onBeforeDone, msBeforeAnotherEffect=1000 }) => {
  //     const transitionStyle = {
  //       transition: Object.keys(style).map(styleType => `${styleType} ${ms}ms ease-in-out`).join(` `),
  //     }
  //     setTransitionStyleAddOn(transitionStyle)
  //     setTransitionTimeout(() => {
  //       setTransitionStyleAddOn({
  //         ...transitionStyle,
  //         ...style,
  //       })
  //       setTransitionTimeout(() => {
  //         const finish = () => {
  //           if(skipFinish) return
  //           onBeforeDone && onBeforeDone()
  //           setTransitionTimeout(() => {
  //             setTransitionStyleAddOn({})
  //             setTransitionTimeout(() => setTransitionStyleAddOn(), msBeforeAnotherEffect)
  //           }, 50)
  //         }
  //         if(reverseEffect) {
  //           setTransitionStyleAddOn(transitionStyle)
  //           setTransitionTimeout(finish, ms)
  //         } else {
  //           finish()
  //         }
  //       }, ms)
  //     })
  //   },
  //   [ setTransitionStyleAddOn, setTransitionTimeout ],
  // )

//   const finishBeyondBoundIndicator = useCallback(
//     () => {
// console.log('gogogogo - finishBeyondBoundIndicator')
//       const transitionStyleAddOn = getTransitionStyleAddOn()
//       if(transitionStyleAddOn) {
//         setTransitionStyleAddOn({
//           transition: transitionStyleAddOn.transition,
//         })
//         setTransitionTimeout(() => {
//           setTransitionStyleAddOn()
//         }, 500)
//       }

//     },
//     [ getTransitionStyleAddOn, setTransitionStyleAddOn, setTransitionTimeout ],
//   )

  const goScale = useCallback(
    ({ newScale, x, y, doTransition }) => {
      let { scale, translateX, translateY } = getTransformValues(getMapTransformValues())
      const newScaleBounded = getBoundedValue(newScale, { max: MAX_MAP_SCALE, min: 1 })

      if(newScaleBounded !== scale) {

        const maxMapTranslateX = (width - baseMapWidth * newScale)
        const maxMapTranslateY = (height - baseMapHeight * newScale)
        const mouseX = x - left
        const mouseY = y - top
        const absX = mouseX - translateX
        const absY = mouseY - translateY
        const changeInScale = newScaleBounded / scale
        translateX -= changeInScale * absX - absX
        translateY -= changeInScale * absY - absY
        translateX = getBoundedValue(translateX, { min: maxMapTranslateX, max: 0 })
        translateY = getBoundedValue(translateY, { min: maxMapTranslateY, max: 0 })

        if(doTransition) {
          setInTransition(
            true,
            () => {
              setMapTransformValues(
                {
                  scale: newScaleBounded,
                  translateX,
                  translateY,
                },
                () => {
                  setTransitionTimeout(
                    () => setInTransition(false),
                    TRANSITION_MS,
                  )
                },
              )
            },
          )
        } else {
          setMapTransformValues({
            scale: newScaleBounded,
            translateX,
            translateY,
          })
        }

      } else if(
        [ 1, MAX_MAP_SCALE ].includes(newScaleBounded)
        && !getBeyondBoundary()
        && Math.abs(1 - (newScale / scale)) > .1
      ) {
        setBeyondBoundary(`scale`)
        setBeyondBoundaryTimeout(() => setBeyondBoundary(``), 250)
      }

    },
    [ getBeyondBoundary, setBeyondBoundary, getTransformValues, getMapTransformValues, setMapTransformValues, width, height, left, top, baseMapWidth, baseMapHeight, setBeyondBoundaryTimeout, setInTransition, setTransitionTimeout ],
  )

  const goMove = useCallback(
    ({ newTranslateX, newTranslateY }) => {
      const { scale, translateX, translateY } = getTransformValues(getMapTransformValues())
      const newTranslateXBounded = getBoundedValue(newTranslateX, { min: maxMapTranslateX, max: 0 })
      const newTranslateYBounded = getBoundedValue(newTranslateY, { min: maxMapTranslateY, max: 0 })

      if(
        newTranslateXBounded !== translateX
        || newTranslateYBounded !== translateY
      ) {
        setMapTransformValues({
          scale,
          translateX: newTranslateXBounded,
          translateY: newTranslateYBounded,
        })
      }

      const beyondX = (
        Math.abs(newTranslateX - translateX) > 15
        && [ maxMapTranslateX, 0 ].includes(newTranslateXBounded)
        && maxMapTranslateX < -100
      )
      const beyondY = (
        Math.abs(newTranslateY - translateY) > 15
        && [ maxMapTranslateY, 0 ].includes(newTranslateYBounded)
        && maxMapTranslateY < -100
      )

      const newBeyondBoundary = `${beyondX ? `x` : ``}${beyondY ? `y` : ``}`
      if(getBeyondBoundary() !== newBeyondBoundary) setBeyondBoundary(newBeyondBoundary)
      if(!getMoving()) setMoving(true)

    },
    [ getBeyondBoundary, setBeyondBoundary, getMapTransformValues, setMapTransformValues, getTransformValues, maxMapTranslateX, maxMapTranslateY, getMoving, setMoving ],
  )

  const onWheel = useCallback(
    event => {
      const { scale } = getTransformValues(getMapTransformValues())
      const { deltaY, clientX: x, clientY: y } = event
      const newScale = scale - (deltaY/1000) * scale
      goScale({ newScale, x, y })
    },
    [ goScale, getTransformValues, getMapTransformValues ],
  )

  const onMouseDown = useCallback(
    event => {

      event.preventDefault()

      const { clientX, clientY } = event
      let { translateX, translateY } = getTransformValues(getMapTransformValues())

      const move = event2 => {
        const newTranslateX = translateX + (event2.clientX - clientX)
        const newTranslateY = translateY + (event2.clientY - clientY)
        goMove({ newTranslateX, newTranslateY })
      }

      const onMouseUp = () => {
        document.body.removeEventListener('mousemove', move)
        document.body.removeEventListener('mouseup', onMouseUp)
        document.body.removeEventListener('mouseleave', onMouseUp)
        if(getBeyondBoundary()) setBeyondBoundary(``)
        setTimeout(() => setMoving(false))
      }

      document.body.addEventListener('mousemove', move)
      document.body.addEventListener('mouseup', onMouseUp)
      document.body.addEventListener('mouseleave', onMouseUp)

    },
    [ getTransformValues, getMapTransformValues, goMove, getBeyondBoundary, setBeyondBoundary, setMoving ],
  )

  const onDoubleClick = useDoubleClickCallback(
    event => {
      event.preventDefault()
      const { clientX: x, clientY: y } = event
      let { scale } = getTransformValues(getMapTransformValues())
      goScale({ newScale: scale * 2, x, y, doTransition: !!TRANSITION_MS })
    },
  )

  const adjustedScale = scale / MAX_MAP_SCALE

  const transition = inTransition ? `transform ${TRANSITION_MS}ms ease-in-out` : `none`

  const scaleAdjusterStyle = baseMapWidth && {
    width: baseMapWidth * MAX_MAP_SCALE,
    height: baseMapHeight * MAX_MAP_SCALE,
    transform: `scale(${adjustedScale})`,
    transition,
  }

  const positionAdjusterStyle = {
    transform: `translate(${translateX}px, ${translateY}px)`,
    transition,
  }

  const getPinStyle = ({ x, y }) => ({
    transform: `scale(${1/adjustedScale})`,
    transition,
    left: `${Math.round(baseMapWidth * x * 100)}px`,
    top: `${Math.round(baseMapHeight * y * 100)}px`,
  })

  const mapViewInfo = {
    scale,
    translateX,
    translateY,
    top,
    left,
    baseMapWidth,
    baseMapHeight,
  }

  return {
    scaleAdjusterStyle,
    positionAdjusterStyle,
    getPinStyle,
    mapViewInfo,
    moving,
    ref,
    onWheel,
    onMouseDown,
    onClick: onDoubleClick,
    // onTouchStart,
    $opacity: beyondBoundary ? .4 : 1,
  }

}

export default useDragMap

/*
  TODO:
    - pinch to zoom
    - zoom out all the way puts Israel in the center
    - db setup
      - don't forget
        - regions
    - manually add 3-4 entries
    - add timeline
    - set up admin to add/edit/delete entries
*/
