import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import useEditingLock from '../../../hooks/useEditingLock'
import { cloneObj, timeBetweenMapDates } from '../../../utils/misc'
import mapDataIsValid from './mapDataIsValid'

import updateMapLayerMutation from '../../../graphql/mutations/updateMapLayer'
import BibleMapEditorPlace from './BibleMapEditorPlace'
import BibleMapEditorButtons from './BibleMapEditorButtons'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorEvent from './BibleMapEditorEvent'
import BibleMapEditorJourney from './BibleMapEditorJourney'
import BibleMapEditorPerson from './BibleMapEditorPerson'
import useEffectAsync from '../../../hooks/useEffectAsync'

export const TransitionRight = props => <Slide {...props} direction="right" />

const StyledSnackbar = styled(Snackbar)`

  z-index: 100;
  margin-top: 60px;

  .MuiSnackbarContent-root {
    min-width: 0;
    max-width: none;
    max-height: calc(100vh - 60px - 50px);
    padding: 0;
    flex-direction: column;
    justify-content: center;
    background: white;
    color: black;
  }

  .MuiSnackbarContent-message {
    width: 300px;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
  }

  .MuiSnackbarContent-action {
    display: none;
  }

`

const Heading = styled.div`
  padding: 15px;
`

const Title = styled.div`
  font-size: 17px;
`

const InUse = styled.div`
  font-weight: bold;
`

const InUseExplanation = styled.div`
  margin-top: 10px;
`

const BackButton = styled(Button)`
  font-size: 10px;
  padding: 2px 14px;
  margin: 0 0 10px;

  .MuiSvgIcon-root {
    font-size: 11px;
  }
`

const MoveButton = styled(BackButton)`
  position: absolute;
  right: 15px;
  top: 15px;
`

const MovingPlaceInsructions = styled.div`
  padding: 0 15px 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const BibleMapEditorSnackbar = ({
  open,
  mapLayerId,
  setMapLayer,
  setPlaceInEdit,
  closeEdit,
  placeInEdit,
  places,
  journeys,
  persons,
  movingPlace,
  toggleMovingPlace,
  ...otherProps
}) => {

  const layer = `BIBLE`

  const {
    requestingLock,
    lockObtained,
    requestId,
    lockedMsg,
    lockedExplanation,
  } = useEditingLock({ type: `MAP:${layer}`, open })

  const [ updateMapLayer ] = useMutation(updateMapLayerMutation)

  const [ eventInEdit, setEventInEdit ] = useState()
  const [ journeyInEdit, setJourneyInEdit ] = useState()
  const [ personInEdit, setPersonInEdit ] = useState()
  
  const [ updating, setUpdating ] = useState()

  const doUpdateMapLayer = useCallback(
    async ({ place, event, journey, person, placeId }) => {

      setUpdating(true)

      let goSet

      const getUpdatedItem = (item, itemsSet, setFunc) => {
        if(item) {
          const updatedSet = [ ...itemsSet ]
          const clonedItem = cloneObj(item)
          clonedItem.id = clonedItem.id || uuidv4()
          const idx = itemsSet.findIndex(({ id }) => id === clonedItem.id)
          if(clonedItem.delete) {
            if(idx !== -1) {
              updatedSet.splice(idx, 1)
            }
          } else if(idx === -1) {
            if(item === place) {
              clonedItem.events = []
            }
            updatedSet.push(clonedItem)
          } else {
            if(updatedSet[idx].events) {
              clonedItem.events = updatedSet[idx].events
            }
            updatedSet[idx] = clonedItem
          }
          if(!clonedItem.delete) {
            goSet = () => setFunc(clonedItem)
          }
          return updatedSet
        } else if(itemsSet.events && event) {
          return getUpdatedItem(event, itemsSet.events)
        } else {
          return itemsSet
        }
      }

      const data = {
        places: getUpdatedItem(place, places, setPlaceInEdit),
        journeys: getUpdatedItem(journey, journeys, setJourneyInEdit),
        persons: getUpdatedItem(person, persons, setPersonInEdit),
      }

      if(event && placeId) {
        data.places = [ ...data.places ]
        const idx = data.places.findIndex(({ id }) => id === placeId)
        data.places[idx] = { ...data.places[idx] }
        const setFunc = clonedEvent => {
          setPlaceInEdit(data.places[idx])
          setEventInEdit(clonedEvent)
        }
        if(event.delete) {
          goSet = () => setPlaceInEdit(data.places[idx])
        }
        data.places[idx].events = getUpdatedItem(event, data.places[idx].events, setFunc)
        data.places[idx].events.sort((a,b) => {
          const [ a1, a2 ] = (a.dates[0].date || ``).split(` - `)
          const [ b1, b2 ] = (b.dates[0].date || ``).split(` - `)
          return (
            (a1 === b1 && timeBetweenMapDates(a2 || a1, b2 || b1).totalDays * -1)
            || timeBetweenMapDates(a1, b1).totalDays * -1
          )
        })
      }

      if(journey) {
        data.journeys.sort((a,b) => a.names[0].name < b.names[0].name ? -1 : 1)
      }

      if(person) {
        data.persons.sort((a,b) => a.names[0].name < b.names[0].name ? -1 : 1)
      }

      try {
        mapDataIsValid(data)
      } catch(e) {
        alert(e.message || `Invalid map data: unknown reason`)
        setUpdating(false)
        return
      }

      const { data: { updateMapLayer: newCreatedAt } } = await updateMapLayer({
        variables: {
          layer,
          data,
          editingLockRequestId: requestId,
        },
      })

      setMapLayer({
        createdAt: newCreatedAt,
        data,
      })

      goSet && goSet()

      setUpdating(false)

    },
    [ layer, setUpdating, places, journeys, persons, requestId, setMapLayer, updateMapLayer, setPlaceInEdit, setJourneyInEdit, setPersonInEdit, setEventInEdit ],
  )

  const backToPlace = useCallback(
    () => {
      setEventInEdit()
      setJourneyInEdit()
      setPersonInEdit()
    },
    [ setEventInEdit, setJourneyInEdit, setPersonInEdit ],
  )

  const backToEvent = useCallback(
    () => {
      setJourneyInEdit()
      setPersonInEdit()
    },
    [ setJourneyInEdit, setPersonInEdit ],
  )

  useEffectAsync(
    () => {
      if(!open) {
        backToPlace()
      }
    },
    [ open ],
  )

  useEffectAsync(
    async () => {
      if(movingPlace && (placeInEdit || {}).id) {
        await doUpdateMapLayer({ place: placeInEdit })
        toggleMovingPlace()
      }
    },
    [ placeInEdit ],
  )

  const title = (
    (journeyInEdit && (journeyInEdit.id ? i18n("Edit Journey") : i18n("Create Journey")))
    || (personInEdit && (personInEdit.id ? i18n("Edit Person") : i18n("Create Person")))
    || (eventInEdit && (eventInEdit.id ? i18n("Edit Event") : i18n("Create Event")))
    || ((placeInEdit || {}).id ? i18n("Edit Place") : i18n("Create Place"))
  )

  const editingPlace = !eventInEdit && !journeyInEdit && !personInEdit
  const editingJourneyOrPerson = !!(journeyInEdit || personInEdit)

  const BibleMapEditorPiece = (
    (editingPlace && BibleMapEditorPlace)
    || (journeyInEdit && BibleMapEditorJourney)
    || (personInEdit && BibleMapEditorPerson)
    || (eventInEdit && BibleMapEditorEvent)
  )

  return (
    <StyledSnackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      TransitionComponent={TransitionRight}
      message={
        <>

          <Heading>
            {!editingPlace &&
              <BackButton
                onClick={editingJourneyOrPerson ? backToEvent : backToPlace}
                variant="contained"
                disableElevation
                startIcon={<ArrowBackIcon />}
              >
                {editingJourneyOrPerson ? i18n("Back to Event") : i18n("Back to Place")}
              </BackButton>
            }
            {editingPlace && lockObtained && (placeInEdit || {}).id &&
              <MoveButton
                onClick={toggleMovingPlace}
                variant="contained"
                disableElevation
              >
                {movingPlace ? i18n("Cancel Move") : i18n("Move the Dot")}
              </MoveButton>
            }
            <Title>
              {title}
            </Title>
          </Heading>

          {!requestingLock && !lockObtained &&
            <>
              <BibleMapEditorBody>
                <InUse>
                  {lockedMsg}
                </InUse>
                <InUseExplanation>
                  {lockedExplanation}
                </InUseExplanation>
              </BibleMapEditorBody>
              <BibleMapEditorButtons
                closeEdit={closeEdit}
                requestingLock={requestingLock}
                lockObtained={lockObtained}
              />
            </>
          }

          {(requestingLock || lockObtained) && !movingPlace &&
            <BibleMapEditorPiece
              placeInEdit={placeInEdit}
              eventInEdit={eventInEdit}
              journeyInEdit={journeyInEdit}
              personInEdit={personInEdit}
              mapLayerId={mapLayerId}
              closeEdit={closeEdit}
              doUpdateMapLayer={doUpdateMapLayer}
              requestingLock={requestingLock}
              lockObtained={lockObtained}
              updating={updating}
              backToPlace={backToPlace}
              backToEvent={backToEvent}
              setEventInEdit={setEventInEdit}
              setJourneyInEdit={setJourneyInEdit}
              setPersonInEdit={setPersonInEdit}
              placeId={(placeInEdit || {}).id}
              journeys={journeys}
              persons={persons}
              places={places}
            />
          }

          {(requestingLock || lockObtained) && movingPlace &&
            <MovingPlaceInsructions>
              {i18n("Click on the map to select a new spot for this place.")}
            </MovingPlaceInsructions>
          }

        </>
      }
      {...otherProps}
    />
  )
}

export default memo(BibleMapEditorSnackbar)