import { memo, useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { i18n } from 'inline-i18n'
import { useLocation } from "react-router-dom"

import useAccountSetting from '../../hooks/useAccountSetting'
import { getBoundedValue, shouldRecommendInstall, isPWA } from '../../utils/misc'
import useRefState from '../../hooks/useRefState'
import useAppSize from '../../hooks/useAppSize'
import useEffectAsync from '../../hooks/useEffectAsync'
import useSetTimeout from '../../hooks/useSetTimeout'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useDataQuery from '../../hooks/useDataQuery'
import { LoggedInUserContext } from '../../context/LoggedInUser'

import SvgArrow from './SvgArrow'

import channelQuery from '../../graphql/queries/channel'

const CENTER_CIRCLE_DIST_FROM_ARROW_END = 500
const CIRCLE_SIZE = 280

const getThemeColor = ({ theme, $color }) => {
  const keys = $color.split(`.`)
  let clr = theme.palette
  while(keys.length > 0) {
    clr = clr[keys.shift()]
  }
  return clr
}

const Circle = styled.div`
  position: absolute;
  z-index: 7500;
  left: ${({ $leftToCenter }) => $leftToCenter - CIRCLE_SIZE/2}px;
  top: ${({ $topToCenter }) => $topToCenter - CIRCLE_SIZE/2}px;
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  background: ${getThemeColor};
  border-radius: 50%;
  padding: 30px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
`

const OneMinTour = styled.div`
  font-size: 11px;
  margin: -3px 0 8px;
  font-weight: 300;
`

const TourName = styled.div`
  background: rgb(0 0 0/.25);
  border-radius: 3px;
  padding: 4px 7px;
  text-transform: uppercase;
  font-size: 10px;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 1.3;
`

const TextPiece = styled.span`
  font-weight: ${({ $light }) => $light ? 300 : 400};
  opacity: ${({ $light }) => $light ? .7 : 1};
`

const StyledSvgArrow = styled(SvgArrow)`
  z-index: 7400;

  & > path {
    stroke: ${getThemeColor};
    stroke-width: 7px;
  }

  & > polygon {
    fill: ${getThemeColor};
  }
`

const StyledButton = styled(Button)`
  background-color: black;
  color: white;
  margin-top: 15px;
  transition: transform .15s ease-in-out;

  &:hover {
    transform: scale(1.1);
    background-color: black;
  }
`

const LaterNeverButtons = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 10px;
`

const LaterNeverButton = styled(Button)`
  color: white;
  margin-top: -5px;
  background-color: rgb(0 0 0/.5);

  &:hover {
    background-color: black;
  }`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: black;
  color: white;
  padding: 8px;
  transition: transform .15s ease-in-out;

  &:hover {
    transform: scale(1.2);
    background-color: black;
  }

  .MuiSvgIcon-root {
    font-size: 17px;
  }
`

const churchPathnameRegex = /^\/church\/[^/]+$/

const getTour = ({ pathname, user, iAmChurchAdmin, userSelectedTourType }) => {

  const closeModals = async () => {
    const popoverBGs = [ ...document.body.querySelectorAll(`.MuiPopover-root > div:first-child, .MuiBackdrop-root, .options-popper-clear-cover`) ]
    popoverBGs.forEach(el => el.click())
  }

  const scrollUp = async () => {
    await closeModals()
    const firstPassageScrollContainer = document.body.querySelector(`.PassageContent-ScrollContainer`)
    if(firstPassageScrollContainer) {
      firstPassageScrollContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  const prepPlayer = async () => {
    await closeModals()
    let normal = document.body.querySelector(`.StudyBibleItemThumbnail-Container2-normal`)
    const mini = document.body.querySelector(`.StudyBibleItemThumbnail-Container2-mini`)
    if(!normal && mini) {
      mini.click()
      await new Promise(resolve => requestAnimationFrame(resolve))
      normal = document.body.querySelector(`.StudyBibleItemThumbnail-Container2-normal`)
    }
    if(normal) {
      normal.click()
      await new Promise(resolve => requestAnimationFrame(resolve))
    }
  }

  const closePlayer = async () => {
    await closeModals()
    const closeIcon = document.body.querySelector(`.VideoPlayerCustomControls-CloseIcon`)
    if(closeIcon) {
      closeIcon.click()
    }
  }

  const openMenuIfNeeded = async () => {
    await closeModals()
    const accountIcon = document.body.querySelector(`.AccountMenuButton-StyledIconButton`)
    if(!accountIcon) {
      const menuIcon = document.body.querySelector(`.Header-MenuIconButton`)
      if(menuIcon) {
        menuIcon.click()
        await new Promise(resolve => requestAnimationFrame(resolve))
      }
    }
  }

  const tours = [

    // browser tour

    // app tour
    
    // cssb tour
    {
      type: `cssb`,
      name: i18n("Church Study Bibles"),
      color: `tertiary.main`,
      pathnameRegex: churchPathnameRegex,
      beginSelector: `.text-content-word`,
      introText: (
        iAmChurchAdmin
          ? [
            i18n("Your Church Study Bible lives!"),
            i18n("Learn the features."),
          ]
          : i18n("Learn the features.")
      ),
      requireTour: iAmChurchAdmin && !userSelectedTourType,
      steps: [
        {
          text: i18n("Tap a verse number to compare Bible versions and check the Hebrew or Greek original."),
          selector: `.TextContent-tag-v, .TextContent-tag-vp`,
          prep: scrollUp,
        },
        {
          text: i18n("Tap dots to view cross-references and translation notes."),
          selector: `.TextContent-tag-x`,
          prep: scrollUp,
          // will skip if version doesn't have cfs
        },
        {
          text: i18n("Tap here to switch the passage."),
          selector: `.PassageRefButton`,
          prep: closeModals,
        },
        {
          text: i18n("Search the Bible from here."),
          selector: `.Header-SearcIconButton`,
          noArrowSelector: `.SearchModal-Container`,
          prep: closeModals,
        },
        {
          text: i18n("Tap one of these to listen to a sermon."),
          selector: `.StudyBibleItemThumbnail-Container2-normal`,
          backupText: i18n("Sermons will appear here."),
          backupSelector: `.PassageStudyBibleNotes-None`,
          goNextSelector: `.ChannelItem-Container`,
          prep: async () => {
            const normal = document.body.querySelector(`.StudyBibleItemThumbnail-Container2-normal`)
            const mini = document.body.querySelector(`.StudyBibleItemThumbnail-Container2-mini`)
            if(!normal && mini) {
              mini.click()
            }
          },
        },
        {
          text: i18n("Tap anywhere in the middle to play or pause."),
          selector: `.ChannelItem-Container`,
          prep: prepPlayer,
          padEnd: 0,
          // will skip if they did not tap to listen
        },
        {
          text: i18n("Bookmark, comment, and share sermons with these buttons."),
          selector: `.VideoPlayerCustomControls-createAndEditBookmarks`,
          prep: prepPlayer,
          padEnd: 10,
          // will skip if they did not tap to listen
        },
        {
          text: i18n("Find your listening history and sermon bookmarks in the main menu."),
          selector: `.Header-MenuIconButton`,
          noArrowSelector: `.MuiDrawer-root`,
          prep: closePlayer,
          padEnd: 8,
        },
        ...(isPWA ? [] : [{
          text: (
            user
              ? i18n("To install the app, sign in to biblearc.com on your phone and open this study Bible via the main menu.")
              : i18n("To install the app, open this same URL on your phone.")
          ),
          selector: (
            user
              ? `.Header-MenuIconButton`
              : `.ChannelHeader-LinkIconButton`
          ),
          padEnd: user ? 8 : 10,
          doNotSkip: true,
          prep: closeModals,
        }]),
        {
          text: i18n("Share this study Bible with others in your church!"),
          selector: `.ChannelHeader-LinkIconButton`,
          padEnd: 10,
          doNotSkip: true,
          prep: closeModals,
        },
        ...(!iAmChurchAdmin ? [] : [{
          text: i18n("Complete setup, find stats, and manage your study Bible."),
          selector: `.ChannelHeader-SettingsIconButton`,
          padEnd: 10,
          doNotSkip: true,
          prep: closeModals,
        }]),
        ...(!!user ? [] : [{
          text: i18n("Sign in or create a free Biblearc account. This allows you to bookmark and discuss sermons."),
          selector: `.AccountMenuButton-StyledIconButton`,
          doNotSkip: true,
          prep: openMenuIfNeeded,
        }]),
      ],
    },

  ]

  return (
    (
      !shouldRecommendInstall
      && tours.find(({ pathnameRegex }) => pathnameRegex.test(pathname))
    ) || {}
  )
}

const Tour = ({
  userSelectedTourType,
  setUserSelectedTourType,
}) => {

  const location = useLocation()
  const { pathname } = location
  const user = useContext(LoggedInUserContext)

  let [ stepIdx, setStepIdx, getStepIdx ] = useRefState(-1)
  const { width, height } = useAppSize()
  const [ setReadyToBeginTimeout ] = useSetTimeout()
  const [ setGoNextTimeout ] = useSetTimeout()
  const [ readyToBegin, setReadyToBegin ] = useState(false)
  const circleRef = useRef()

  const { channel, loading: channelLoading } = useDataQuery({
    channelQuery,
    variables: {
      id: pathname.split(`/`)[2],
    },
    skip: !user || !churchPathnameRegex.test(pathname),
    sticky: true,
  })
  const iAmChurchAdmin = !!user && ((channel || {}).channelAdmins || []).some(channelAdmin => (channelAdmin.user || {}).id === user.id)

  // figure out which tour is possible based on the location
  const possibleTour = useMemo(
    () => (
      getTour({
        pathname,
        user,
        iAmChurchAdmin,
        userSelectedTourType,
      })
    ),
    [ pathname, user, iAmChurchAdmin, userSelectedTourType ],
  )

  // see if this tour has been done yet
  const [ closed, toggleClosed ] = useSimpleToggle()
  const [ tourTaken, setTourTaken, x, tourTakenLoading ] = useAccountSetting(`tour-${possibleTour.type}-taken`, false)  // eslint-disable-line no-unused-vars

  const { type, beginSelector, steps, name, color, introText, requireTour } = (
    (
      userSelectedTourType
        ? (userSelectedTourType === possibleTour.type)
        : (!tourTaken || stepIdx > -1)
    )
      ? possibleTour
      : {}
  )

  stepIdx = getBoundedValue(stepIdx, { min: -1, max: (steps || []).length })

  const { prep, text, selector, noArrowSelector, backupText, backupSelector, goNextSelector, goNextDelay, padEnd=18, doNotSkip } = (steps || [])[stepIdx] || {}
  const [ placementInfo, setPlacementInfo, getPlacementInfo ] = useRefState()
  const { textToUse, circleInfo, ref2 } = placementInfo || {}

  const advance = useCallback(() => setStepIdx(getStepIdx() + 1), [ setStepIdx, getStepIdx ])
  const onNoThanks = useCallback(() => setTourTaken(true), [ setTourTaken ])

  const onBegin = useCallback(
    () => {
      advance()
      setTourTaken(true)
    },
    [ advance, setTourTaken ],
  )

  useEffectAsync(
    async () => {

      setPlacementInfo()
      prep && await prep()

      await new Promise(resolve => requestAnimationFrame(resolve))

      const noArrowCircleInfo = {
        $leftToCenter: width/2,
        $topToCenter: height/2,
      }

      if(stepIdx === -1) {

        setPlacementInfo({
          circleInfo: noArrowCircleInfo,
          textToUse: introText,
        })

      } else if(stepIdx === (steps || []).length) {

        setPlacementInfo({
          circleInfo: noArrowCircleInfo,
          textToUse: [
            i18n("That’s it!"),
            i18n("The tour is complete."),
          ],
        })

      } else {

        let el = document.body.querySelector(selector)
        let textToUse = text

        if(!el && selector !== null) {
          el = document.body.querySelector(backupSelector)
          textToUse = backupText || text
          if(!el && backupSelector !== null && !doNotSkip) {
            advance()
            return
          }
        }

        if(!el) {
          setPlacementInfo({
            circleInfo: noArrowCircleInfo,
            textToUse,
          })
          return
        }

        const elRect = el.getBoundingClientRect()

        const arrowEndLeft = getBoundedValue(elRect.left + elRect.width/2, { min: 0, max: width })
        const arrowEndTop = getBoundedValue(elRect.top + elRect.height/2, { min: 0, max: height })

        const fractionLeft = arrowEndLeft / width
        const fractionTop = arrowEndTop / height
        const horizontalShiftDirection = fractionLeft < .5 ? 1 : -1
        const verticalShiftDirection = fractionTop < .5 ? 1 : -1
        const portionLeft = Math.abs(fractionLeft - .5) / (Math.abs(fractionLeft - .5) + Math.abs(fractionTop - .5))
        const portionTop = 1 - portionLeft
        const allowedOverflow = 10
        const leftToCenter = arrowEndLeft + CENTER_CIRCLE_DIST_FROM_ARROW_END * portionLeft * horizontalShiftDirection
        const boundedLeftToCenter = getBoundedValue(leftToCenter, { min: CIRCLE_SIZE/2 - allowedOverflow, max: width - CIRCLE_SIZE/2 + allowedOverflow })
        const topToCenter = arrowEndTop + CENTER_CIRCLE_DIST_FROM_ARROW_END * portionTop * verticalShiftDirection + (Math.abs(leftToCenter - boundedLeftToCenter) * verticalShiftDirection)
        const boundedTopToCenter = getBoundedValue(topToCenter, { min: CIRCLE_SIZE/2 - allowedOverflow, max: height - CIRCLE_SIZE/2 + allowedOverflow })

        const circleInfo = {
          $leftToCenter: boundedLeftToCenter,
          $topToCenter: boundedTopToCenter,
        }

        setPlacementInfo({
          textToUse,
          circleInfo,
          ref2: { current: el },
        })

      }

    },
    [ readyToBegin, stepIdx, steps ],
  )

  useEffectAsync(
    () => {
      setReadyToBegin(false)
      const checkReadyToBegin = () => {
        const ready = !!(beginSelector && document.body.querySelector(beginSelector))
        if(ready) {
          setReadyToBegin(true)
        } else {
          setReadyToBeginTimeout(checkReadyToBegin, 100)
        }
      }
      checkReadyToBegin()
    },
    [ type ],
  )

  useEffect(
    () => {
      if(!goNextSelector && !noArrowSelector) return

      const interval = setInterval(
        () => {
          if(document.body.querySelector(goNextSelector)) {
            clearInterval(interval)
            setGoNextTimeout(advance, goNextDelay || 0)
          } else if(document.body.querySelector(noArrowSelector)) {
            const placementInfo = { ...(getPlacementInfo() || {}) }
            if(placementInfo.ref2) {
              delete placementInfo.ref2
              setPlacementInfo(placementInfo)
            }
          }
        },
        50,
      )

      return () => {
        clearInterval(interval)
      }
    },
    [ noArrowSelector, goNextSelector, goNextDelay, advance, setGoNextTimeout, getPlacementInfo, setPlacementInfo ],
  )

  useEffectAsync(
    () => {
      toggleClosed({ force: false })
    },
    [ userSelectedTourType ],
  )

  useEffectAsync(
    () => {
      if(closed) {
        setUserSelectedTourType()
        setStepIdx(-1)
      }
    },
    [ closed ],
  )

  if(channelLoading) return null
  if(tourTakenLoading) return null
  if(!steps) return null
  if(!circleInfo) return null
  if(closed) return null
  if(!textToUse && stepIdx !== -1) return null

  return (
    <>

      <StyledSvgArrow
        arrowHeadSize={14}
        ref1={circleRef}
        ref2={ref2}
        padEnd={padEnd}
        $color={color}
        className="dark-mode-exempt"
        // controlPointStretch
      />

      <Circle
        ref={circleRef}
        $color={color}
        {...circleInfo}
        className="dark-mode-exempt"
      >

        <TourName>
          {name}
        </TourName>

        <OneMinTour>
          {i18n("A One Minute Tour")}
        </OneMinTour>

        {!!textToUse &&
          <Text>
            {(textToUse instanceof Array ? textToUse : [ textToUse ]).map((text, idx) => (
              <TextPiece
                key={idx}
                $light={idx !== 0}
              >
                {text}
                {` `}
              </TextPiece>
            ))}
          </Text>
        }

        <StyledButton
          onClick={
            stepIdx === -1
              ? onBegin
              : (
                stepIdx < steps.length
                  ? advance
                  : toggleClosed
              )
          }
          variant="contained"
          disableElevation
        >
          {
            stepIdx === -1
              ? i18n("Begin Tour")
              : (
                stepIdx < steps.length
                  ? i18n("Got it")
                  : i18n("Close")
              )
          }
        </StyledButton>

        {!requireTour && stepIdx === -1 && !userSelectedTourType &&
          <LaterNeverButtons>
            <LaterNeverButton
              onClick={toggleClosed}
              variant="contained"
              disableElevation
              size="small"
            >
              {i18n("Later")}
            </LaterNeverButton>
            <LaterNeverButton
              onClick={onNoThanks}
              variant="contained"
              disableElevation
              size="small"
            >
              {i18n("No Thanks")}
            </LaterNeverButton>
          </LaterNeverButtons>
        }

        {!requireTour && (stepIdx !== -1 || !!userSelectedTourType) &&
          <CloseIconButton onClick={toggleClosed}>
            <CloseIcon />
          </CloseIconButton>
        }

      </Circle>

    </>
  )
}

export default memo(Tour)
