import { useCallback, useMemo, useState } from 'react'

import useLayoutEffectAsync from './useLayoutEffectAsync'
import useGoUpdateModuleMarkup from './useGoUpdateModuleMarkup'

export const moduleMarkupOnActiveLens = ({ lenses, formattingKeyInfoId, selectedColor }) => ({ container, positionInfo, color }) => (
  lenses === `OFF`
  || (
    container === `formattingKeyId:${formattingKeyInfoId}`
    && !!positionInfo
    && [ color, `ALL`, undefined, null ].includes(selectedColor)
  )
)

export const wordIsSameOrBefore = (a,b) => (
  a.loc < b.loc
  || (
    a.loc === b.loc
    && a.wordNumberInVerse <= b.wordNumberInVerse
  )
)

export const getWordObjFromEl = el => {
  if(!el) return {}
  const [ loc, wordNumberInVerse ] = el.getAttribute(`data-word-loc`).split(':')
  return {
    loc,
    wordNumberInVerse: parseInt(wordNumberInVerse, 10),
  }
}

const useTextSelectionMarkup = ({
  projectId,
  moduleId,
  moduleMarkups,
  formattingKeyInfo,
  lenses,
  selectedColor,
  setSelectedColor,
  selectionStart,
  selectionEnd,
  firstWordEl,
  lastWordEl,
}) => {

  const formattingKeyInfoId = (formattingKeyInfo || {}).id

  const [ moduleMarkupsToDelete, setModuleMarkupsToDelete ] = useState([])
  const [ goCreateModuleMarkup, goDeleteModuleMarkup ] = useGoUpdateModuleMarkup({
    projectId,
    moduleId,
    moduleMarkup: moduleMarkupsToDelete[0],
  })

  const moduleMarkupByTypeAndColor = useMemo(
    () => {

      const moduleMarkupByTypeAndColor = {}

      const markupRangeMatches = ({ start, end }) => (
        start.loc === selectionStart.loc
        && start.wordNumberInVerse === selectionStart.wordNumberInVerse
        && end.loc === selectionEnd.loc
        && end.wordNumberInVerse === selectionEnd.wordNumberInVerse
      )

      moduleMarkups.forEach(moduleMarkup => {
        const { type, color, positionInfo } = moduleMarkup || {}
        const { start, end } = positionInfo || {}
        if(
          moduleMarkupOnActiveLens({ lenses, formattingKeyInfoId })(moduleMarkup)
          && markupRangeMatches({ start, end })
        ) {
          moduleMarkupByTypeAndColor[`${type} ${color}`] = moduleMarkup
        }
      })

      return moduleMarkupByTypeAndColor

    },
    [ selectionStart, selectionEnd, moduleMarkups, lenses, formattingKeyInfoId ],
  )

  const toggleMarkup = useCallback(
    event => {
      event.preventDefault && event.preventDefault()
      const type = event.markupType || event.target.closest(`[data-type]`).getAttribute(`data-type`)
      const color = event.color || event.target.closest(`[data-color]`).getAttribute(`data-color`)
      const moduleMarkupToRemove = moduleMarkupByTypeAndColor[`${type} ${color}`]
      if(moduleMarkupToRemove) {
        setModuleMarkupsToDelete([ moduleMarkupToRemove ])
      } else {
        goCreateModuleMarkup({
          container: `formattingKeyId:${formattingKeyInfoId}`,
          positionInfo: {
            start: selectionStart,
            end: selectionEnd,
          },
          type,
          color,
        })
        if(![ color, `ALL` ].includes(selectedColor)) {
          setSelectedColor(`ALL`)
        }
      }
    },
    [ moduleMarkupByTypeAndColor, setModuleMarkupsToDelete, goCreateModuleMarkup, selectionStart, selectionEnd, formattingKeyInfoId, selectedColor, setSelectedColor ],
  )

  const clearMarkup = useMemo(
    () => {

      const moduleMarkupsToRemove = []
      const moduleMarkupsToCreate = []

      moduleMarkups.forEach(moduleMarkup => {
        const { positionInfo, container, type, color } = moduleMarkup || {}
        const { start, end } = positionInfo || {}
        if(moduleMarkupOnActiveLens({ lenses, formattingKeyInfoId, selectedColor })(moduleMarkup)) {
          if(
            wordIsSameOrBefore(selectionStart, end)
            && wordIsSameOrBefore(start, selectionEnd)
          ) {

            moduleMarkupsToRemove.push(moduleMarkup)

            const allWordsInThisMarkupModule = [ ...firstWordEl.closest(`.MarkupContent-StyledTextContentMarkupContainer`).querySelectorAll(`.text-content-word[data-word-loc]`) ]
            const wordElBeforeSelectionStart = allWordsInThisMarkupModule[allWordsInThisMarkupModule.findIndex(el => el === firstWordEl) - 1]
            const wordBeforeSelectionStart = getWordObjFromEl(wordElBeforeSelectionStart)
            const lastWordElIdx = allWordsInThisMarkupModule.findIndex(el => el === lastWordEl)
            const wordElAfterSelectionStart = lastWordElIdx !== -1 ? allWordsInThisMarkupModule[lastWordElIdx + 1] : null
            const wordAfterSelectionEnd = getWordObjFromEl(wordElAfterSelectionStart)

            if(wordIsSameOrBefore(start, wordBeforeSelectionStart)) {
              moduleMarkupsToCreate.push({
                container,
                positionInfo: {
                  start,
                  end: wordBeforeSelectionStart,
                },
                type,
                color,
              })
            }

            if(wordIsSameOrBefore(wordAfterSelectionEnd, end)) {
              moduleMarkupsToCreate.push({
                container,
                positionInfo: {
                  start: wordAfterSelectionEnd,
                  end,
                },
                type,
                color,
              })
            }

          }
        }
      })

      if(moduleMarkupsToRemove.length > 0) {
        return () => {
          moduleMarkupsToCreate.forEach(goCreateModuleMarkup)
          setModuleMarkupsToDelete(moduleMarkupsToRemove)
        }
      }

    },
    [ selectionStart, selectionEnd, moduleMarkups, lenses, formattingKeyInfoId, selectedColor, goCreateModuleMarkup, firstWordEl, lastWordEl ],
  )

  useLayoutEffectAsync(
    () => {
      if(moduleMarkupsToDelete[0]) {
        goDeleteModuleMarkup()
        const newModuleMarkupsToDelete = [ ...moduleMarkupsToDelete ]
        newModuleMarkupsToDelete.shift()
        setModuleMarkupsToDelete(newModuleMarkupsToDelete)
      }
    },
    [ moduleMarkupsToDelete ],
  )

  return {
    moduleMarkupByTypeAndColor,
    toggleMarkup,
    clearMarkup,
  }
}

export default useTextSelectionMarkup
