import { memo, useRef, useMemo } from 'react'
import styled from 'styled-components'
// import { i18n } from 'inline-i18n'
import { getCorrespondingRefs, getLocFromRef, getRefFromLoc } from '@bibletags/bibletags-versification'
import { useMeasure } from 'react-use'

import { IS_EMBED, KJV_VERSION } from '../../../utils/constants'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useVersesPieces from '../../../hooks/useVersesPieces'
import useGoUpdateModule from '../../../hooks/useGoUpdateModule'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useMarkupShortcuts from '../../../hooks/useMarkupShortcuts'
import useMirrorRefs from '../../../hooks/useMirrorRefs'
import useDataQuery from '../../../hooks/useDataQuery'
import useVersionInfo from '../../../hooks/useVersionInfo'
import { getEmbedMode } from '../../../graphql/links/embedLink'
import selectText from './selection-utils/selectText'
import { moduleMarkupOnActiveLens } from '../../../hooks/useTextSelectionMarkup'
import { getOrigVersionInfo } from '../../../utils/misc'

import Loading from '../../common/Loading'
import FadedLoading from '../../common/FadedLoading'
import ContainerWithPassagePopper from '../../passage/ContainerWithPassagePopper'
import MarkupContent from './MarkupContent'

import moduleMarkupsQuery from '../../../graphql/queries/moduleMarkups'

const PADDING_TOP = 60
const EMBED_PADDING_BOTTOM = 60

const OuterContainer = styled.div`
  flex: 1;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  z-index: 1;
`

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 0;
  outline: none;
`

const StyledContainerWithPassagePopper = styled(ContainerWithPassagePopper)`
  position: relative;
  padding: ${PADDING_TOP}px 0 ${() => [ `frozen`, `edit` ].includes(getEmbedMode()) ? `${EMBED_PADDING_BOTTOM}px` : `calc(100vh - 200px)`};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`

const MarkupMainPanel = ({
  module,
  projectId,
  paragraphFormattingSetting,
  // lastScrollPositionSetting,
  lensesSetting,
  filteredFormattingKeyInfos,
  formattingKeyInfoId,
  inEditingMode,
  noneditableViewingMode,
  goUndo,
  goRedo,
  goPrintOrDownload,
  // embedFullScreen,
  moduleSettingsLoading,
  formattingKeyInfo,
  setHeight,
  minimizedKey,
  dotNotePlacementCover,
  moduleWidth,
  setCurrentLensId,
  selectedColor,
  setSelectedColor,
  updateFormattingKeyInfo,
  ...otherProps
}) => {

  const { modulePassages } = module
  const { fromLoc: originalFromLoc=``, toLoc: originalToLoc=``, info={} } = modulePassages[0] || {}
  const { versionId } = info

  const { version=KJV_VERSION } = useVersionInfo(versionId)

  const [ fromLoc, toLoc ] = useMemo(
    () => {
      if(!originalFromLoc || !originalToLoc) return [ ``, `` ]
      return [ originalFromLoc, originalToLoc ].map((loc, idx) => (
        getLocFromRef(
          getCorrespondingRefs({
            baseVersion: {
              ref: getRefFromLoc(loc),
              info: getOrigVersionInfo(),
            },
            lookupVersionInfo: version,
            directionToTryIfSkipped: idx === 0 ? `next` : `previous`,
          }).at(idx === 0 ? 0 : -1)
        )
      ))
    },
    [ originalFromLoc, originalToLoc, version ]
  )

  const { bookId, chapter: startChapter } = getRefFromLoc(fromLoc || ``)
  const { chapter: endChapter } = getRefFromLoc(toLoc || fromLoc || ``)

  const [ pieces, piecesLoading ] = useVersesPieces({
    inlineMarkersOnly: paragraphFormattingSetting.value === `NONE`,
    hideHeadings: paragraphFormattingSetting.value === `PARAGRAPHS-ONLY`,
    ...modulePassages[0].info,
    fromLoc,
    toLoc,
  })

  const { moduleMarkups=[], loading: markupLoading } = useDataQuery({
    moduleMarkupsQuery,
    variables: {
      moduleId: module.id,
    },
  })

  const loading = moduleSettingsLoading || piecesLoading

  let [ measureRef, { height } ] = useMeasure()
  const contentRef = useMirrorRefs(measureRef)
  const containerRef = useRef(null)
  const effectiveModuleWidth = minimizedKey ? moduleWidth : (moduleWidth - (240 + 15))

  // const ready = chaptersReady && contentWidth > 0

  const [ goUpdateModule ] = useGoUpdateModule({ module })
  const getModule = useInstanceValue(module)

  // const [ goSetLastScrollPositionSetting ] = useGoSetModuleSetting({
  //   moduleSetting: lastScrollPositionSetting,
  //   projectId,
  // })

  useMarkupShortcuts({
    getModule,
    goUpdateModule,
    goUndo,
    goRedo,
    goPrintOrDownload,
    noneditableViewingMode,
    containerRef,
    filteredFormattingKeyInfos,
    formattingKeyInfoId,
    setCurrentLensId,
  })

  const markup = useMemo(
    () => (
      moduleMarkups
        .filter(
          moduleMarkupOnActiveLens({
            lenses: lensesSetting.value,
            formattingKeyInfoId,
            selectedColor,
          })
        )
        .map(({ type, color, positionInfo }) => ({
          ...positionInfo,
          type,
          color,
        }))
    ),
    [ moduleMarkups, formattingKeyInfoId, selectedColor, lensesSetting.value ],
  )

  useEffectAsync(
    () => {
      // if(!ready) return
      if(noneditableViewingMode) return
      if(IS_EMBED && getEmbedMode() !== `prep`) return
      // TODO: scroll to right place
    },
    [ loading ],
  )

  useEffectAsync(
    () => {
      if(setHeight && height && !loading) {
        setHeight(height + PADDING_TOP + EMBED_PADDING_BOTTOM)
      }
    },
    [ height, loading ],
  )

  // const currentLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  // const newLastScrollPositionSettingValue = useRef(lastScrollPositionSetting.value)
  // const [ setUpdateLastScrollPositionTimeout ] = useSetTimeout()
  // const updateLastScrollPosition = () => {
  //   if(!equalObjs(currentLastScrollPositionSettingValue.current, newLastScrollPositionSettingValue.current)) {
  //     goSetLastScrollPositionSetting({
  //       value: newLastScrollPositionSettingValue.current,
  //     })
  //     currentLastScrollPositionSettingValue.current = newLastScrollPositionSettingValue.current
  //   }

  // }

  // const onScroll = useCallback(
  //   () => {
  //     // TODO: throttle
  //     if(!isScrolling && containerRef.current && !noneditableViewingMode) {
  //       const rowEls = containerRef.current.querySelectorAll(`[data-index]`)
  //       const containerTop = containerRef.current.getBoundingClientRect().top
  //       for(let idx=0; idx<rowEls.length; idx++) {
  //         const { top } = rowEls[idx].getBoundingClientRect()
  //         const offset = (top - containerTop) * -1
  //         if(offset < 0 || idx === rowEls.length - 1) {
  //           newLastScrollPositionSettingValue.current = {
  //             align: `start`,
  //             index: parseInt(rowEls[idx].getAttribute(`data-index`), 10),
  //             offset,
  //           }
  //           setUpdateLastScrollPositionTimeout(updateLastScrollPosition, 5000)
  //           break
  //         }
  //       }
  //     }
  //   },
  //   [],
  // )

  // useEffect(() => updateLastScrollPosition, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OuterContainer>

      <Container
        className="MarkupMainPanel-Container scroll-container-for-passage-popper"
        ref={containerRef}
        tabIndex={0}
        data-module-id={module.id}
      >

        {!loading &&
          <StyledContainerWithPassagePopper
            ref={contentRef}
            data-dot-container="content"
            width={effectiveModuleWidth - 15}
            selectText={inEditingMode ? selectText : undefined}
            topOffset={120}
            moduleMarkups={moduleMarkups}
            formattingKeyInfo={formattingKeyInfo}
            updateFormattingKeyInfo={updateFormattingKeyInfo}
            lenses={lensesSetting.value}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            projectId={projectId}
            moduleId={module.id}
            inEditingMode={inEditingMode}
            bookId={bookId}
            versionId={versionId}
          >

            <MarkupContent
              projectId={projectId}
              inEditingMode={inEditingMode}
              markup={markup}
              minimizedKey={minimizedKey}
              pieces={pieces}
              versionId={versionId}
              bookId={bookId}
              startChapter={startChapter}
              endChapter={endChapter}
              contentRef={contentRef}
              effectiveModuleWidth={effectiveModuleWidth}
              paragraphFormatting={paragraphFormattingSetting.value}
              {...otherProps}
            />

            {dotNotePlacementCover}

          </StyledContainerWithPassagePopper>
        }

      </Container>

      {loading && <Loading />}
      {!loading && markupLoading && <FadedLoading />}

    </OuterContainer>
  )
}

export default memo(MarkupMainPanel)