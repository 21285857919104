import { useMemo } from "react"
import { getWordsHash, splitVerseIntoWords } from "@bibletags/bibletags-ui-helper"
import { getCorrespondingRefs, getLocFromRef } from '@bibletags/bibletags-versification'

import useVersionInfo from './useVersionInfo'
import useVersesPieces from "./useVersesPieces"
import useDataQuery from './useDataQuery'
import { getOrigVersionInfo, isLegacyOriginalVersion } from "../utils/misc"
import { KJV_VERSION } from '../utils/constants'

import chapterQuery from '../graphql/queries/chapter'
import tagSetQuery from '../graphql/queries/tagSet'

const useTagSet = ({
  passageRef,
  versionId,
  wordNumberInVerse,
  tagSetFetchPolicyNetworkNoRerun,
}) => {

  const loc = getLocFromRef(passageRef || {}).split(':')[0]
  const { bookId, chapter } = passageRef || {}

  const { version={ versificationModel: isLegacyOriginalVersion(versionId) ? `original` : `kjv` } } = useVersionInfo(versionId)

  const { chapter: vss, loading: loadingVss } = useDataQuery({
    chapterQuery,
    variables: {
      bookId,
      chapter,
      versionId,
    },
    dataOnError: null,
    skip: !bookId,
  })

  const { usfm } = (vss || []).find(({ id }) => id === `${loc}-${versionId}`) || {}
  const wordsHash = useMemo(() => usfm && getWordsHash({ usfm }), [ usfm ])

  const translationWords = useMemo(
    () => usfm ? splitVerseIntoWords({ usfm, ...(version || KJV_VERSION) }) : [],
    [ usfm, version ],
  )

  let { tagSet, loading: loadingTagSet } = useDataQuery({
    tagSetQuery,
    variables: {
      id: `${loc}-${versionId}-${wordsHash}`,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !wordsHash,
  })
  if(tagSet && tagSet.id !== `${loc}-${versionId}-${wordsHash}`) {
    // data is stale due to change in params
    loadingTagSet = true
    tagSet = null
  }
  const tags = useMemo(() => (tagSet || {}).tags || [], [ tagSet ])
  const tagSetStatus = (tagSet || {}).status

  const originalRefs = useMemo(
    () => (
      getCorrespondingRefs({
        baseVersion: {
          ref: passageRef || {},
          info: version || KJV_VERSION,
        },
        lookupVersionInfo: getOrigVersionInfo(),
      }) || []
    ),
    [ passageRef, version ],
  )

  const [ fromLoc, toLoc ] = useMemo(() => originalRefs.map(getLocFromRef), [ originalRefs ])

  const [ originalPieces, loadingPieces ] = useVersesPieces({
    fromLoc,
    toLoc,
    versionId: `original`,
    inlineMarkersOnly: true,
    skip: !fromLoc || !passageRef,
    forceIncludeTagsRelatedToEntireVerse: true,
  })

  const originalWordPieces = useMemo(
    () => (originalPieces || []).filter(({ type }) => type === `word`),
    [ originalPieces ],
  )

  const tag = useMemo(
    () => !!wordNumberInVerse && (tags || []).find(tag => tag.t.includes(wordNumberInVerse)),
    [ tags, wordNumberInVerse ],
  )

  return {
    tag,
    tags,
    tagSet,
    tagSetStatus,
    translationWords,
    originalRefs,
    originalPieces,
    originalWordPieces,
    loading: loadingVss || loadingTagSet || loadingPieces,
    loadingVss,
    loadingTagSet,
    loadingPieces,
    wordsHash,
  }
}

export default useTagSet
