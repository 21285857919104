import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { getPassageInfoArrayFromText } from '@bibletags/bibletags-ui-helper'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import { getLocFromRef, getCorrespondingRefs } from '@bibletags/bibletags-versification'

import useRefState from '../../../hooks/useRefState'
import { cloneObj, dedup, getOrigVersionInfo } from '../../../utils/misc'
import { KJV_VERSION } from '../../../utils/constants'

import PassageRef from '../../common/PassageRef'

const Container = styled.div`
  margin: -5px 0 5px;
`

const Heading = styled.div`
  margin-bottom: 5px;
`

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 10px;

  &:empty {
    margin: 0;
  }
`

const BibleMapEditorPassages = ({
  passages,
  onChange,
  disabled,
}) => {

  const [ newPassageStr, setNewPassageStr, getNewPassageStr ] = useRefState(``)
  const [ error, setError, getError ] = useRefState(false)

  const textFieldOnChange = useCallback(
    ({ target }) => {
      setNewPassageStr(target.value)
      if(getError()) setError(false)
    },
    [ setNewPassageStr, getError, setError ],
  )

  const onKeyDown = useCallback(
    event => {
      if(event.key === `Enter`) {
        const refSets = getPassageInfoArrayFromText({ text: getNewPassageStr() }).map(({ refSets }) => refSets).flat()

        let newValue = cloneObj(passages)

        const getConvertedLoc = ({ ref, directionToTryIfSkipped=`next`, atIdx=0 }) => (
          getLocFromRef(
            getCorrespondingRefs({
              baseVersion: {
                ref,
                info: KJV_VERSION,
              },
              lookupVersionInfo: getOrigVersionInfo(),
              directionToTryIfSkipped,
            }).at(atIdx)
          ).split(':')[0]
        )

        if(
          refSets.length === 0
          || refSets.some(refs => refs.some(ref => ref.verse == null))
        ) {
          getNewPassageStr() && setError(true)
          return
        }

        refSets.forEach(refs => {
          const newPassage = {
            fromLoc: getConvertedLoc({ ref: refs[0] }),
          }
          if(refs[1]) {
            newPassage.toLoc = getConvertedLoc({ ref: refs[1], directionToTryIfSkipped: `previous`, atIdx: -1 })
          }
          newValue.push(newPassage)
        })

        newValue = dedup(newValue)
        newValue.sort((a,b) => (parseInt(a.fromLoc + (a.toLoc || a.fromLoc), 10) - parseInt(b.fromLoc + (b.toLoc || b.fromLoc), 10)))

        onChange({ keys: [ `passages` ], newValue })
        setNewPassageStr(``)

      }
    },
    [ passages, getNewPassageStr, onChange, setError, setNewPassageStr ],
  )

  return (
    <Container>

      <Heading>
        {i18n("Passages")}
      </Heading>

      <Chips>
        {passages.map(({ fromLoc, toLoc }, idx) => (
          <Chip
            key={idx}
            label={
              <PassageRef
                fromLoc={fromLoc}
                toLoc={toLoc}
                convertToVersionId={KJV_VERSION}
              />
            }
            size="small"
            onDelete={() => {
              const newValue = cloneObj(passages)
              newValue.splice(idx, 1)
              onChange({ keys: [ `passages` ], newValue })
            }}
          />
        ))}
      </Chips>

      <TextField
        value={newPassageStr}
        placeholder={i18n("Add Passages")}
        variant="outlined"
        size="small"
        fullWidth
        disabled={disabled}
        onChange={textFieldOnChange}
        onKeyDown={onKeyDown}
        error={error}
      />

    </Container>

  )
}

export default memo(BibleMapEditorPassages)