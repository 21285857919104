import { memo, useCallback, useState, useContext } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CheckIcon from '@material-ui/icons/Check'
import LinkIcon from '@material-ui/icons/Link'
import SettingsIcon from '@material-ui/icons/Settings'
import copy from 'copy-to-clipboard'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import useAppSize from '../../../hooks/useAppSize'
import useSetTimeout from '../../../hooks/useSetTimeout'
import { getChannelVars } from './Channel'

import Header from "../../common/Header"
import HomeMiniPassageRefNavigator from '../home/HomeMiniPassageRefNavigator'
import LinkIconButton from '../../common/LinkIconButton'

const HeaderText = styled.div`
  font-weight: 300;
  line-height: 48px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

const HeaderTextContent = styled.span`
  @media (max-width: 600px) {
    display: none;
  }
`

const Image = styled.img`
  width: 29px;
  height: 29px;
  margin: 9.5px 8px 9.5px 4px;
  object-fit: cover;
  border-radius: 5px;
  position: relative;

  &::after {  
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    content: "";
  }
`

const LeftExtraImage = styled(Image)`
  position: absolute;
  left: 53px;
`

const Name = styled.span`
  display: inline-block;
  font-weight: 500;
`

const StyledIconButton = styled(IconButton)`
  padding: 10px;
  margin-left: 2px;
  display: block;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const StyledLinkIconButton = styled(LinkIconButton)`
  padding: 10px;
  display: block;
  position: relative;

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`

const MiniSettingsIconButton = styled(LinkIconButton)`
  position: absolute;
  right: 48px;
  z-index: 1;

  @media (max-width: 370px) {
    z-index: 0;
    opacity: .4;
  }
`

const DaysLeftCount = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  border: 1px solid white;
  color: white;
  font-size: 9px;
  font-weight: 700;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ChannelHeader = ({
  channel,
  loading,
  passageBookmark,
  setPassageBookmarks,
  getPassageBookmarks,
  ...otherProps
}) => {

  const loggedInUser = useContext(LoggedInUserContext)

  const {
    id,
    // name,
    shortName=(loading ? i18n("Loading...") : `??`),
    // description,
    updatedAt,
    // channelAdmins,
    purchasedAt,
    // createdAt,
  } = channel || {}

  const { iAmAnAdmin, numDaysLeft } = getChannelVars({ channel, loggedInUser })

  const { miniHeaderSize } = useAppSize()
  const [ justCopied, setJustCopied ] = useState(false)
  const [ setJustCopiedTimeout ] = useSetTimeout()

  const copyLink = useCallback(
    () => {
      copy(document.location.href)
      setJustCopied(true)
      setJustCopiedTimeout(() => {
        setJustCopied(true)
        setJustCopiedTimeout(() => setJustCopied(false), 1500)
      }, 200)
    },
    [ setJustCopied, setJustCopiedTimeout ],
  )

  const settingsIcon = (
    <>
      <SettingsIcon />
      {!purchasedAt &&
        <DaysLeftCount>
          {numDaysLeft}
        </DaysLeftCount>
      }
    </>
  )

  return (
    <Header
      {...otherProps}
      showBiblearcLogo
      showBibleDisplaySettings
      centerExtraComponent={
        miniHeaderSize
        && (
          <HomeMiniPassageRefNavigator
            passageBookmark={passageBookmark}
            setPassageBookmarks={setPassageBookmarks}
            getPassageBookmarks={getPassageBookmarks}
          />
        )
      }
      leftExtraComponent={
        miniHeaderSize
        && (
          <LeftExtraImage
            src={`${process.env.REACT_APP_ASSETS_URI}${id}-favicon_192-${updatedAt}.png`}
            className="dark-mode-exempt"
          />
        )
      }
      rightExtraComponent={
        miniHeaderSize
        && iAmAnAdmin
        && (
          <MiniSettingsIconButton
            to={`/church/${id}/admin`}
            className="ChannelHeader-SettingsIconButton"
          >
            {settingsIcon}
          </MiniSettingsIconButton>
        )
      }
    >
      {!!channel &&
        <HeaderText>
          <Image
            src={`${process.env.REACT_APP_ASSETS_URI}${id}-favicon_192-${updatedAt}.png`}
            className="dark-mode-exempt"
          />
          <HeaderTextContent>
            {i18nReact("The {{short_name}} Study Bible", {
              short_name: (
                <Name>
                  {shortName}
                </Name>
              ),
            })}
          </HeaderTextContent>
          <Tooltip
            title={i18n("Copy link to share")}
          >
            <StyledIconButton
              onClick={copyLink}
              color={justCopied ? `primary` : `default`}
              className="ChannelHeader-LinkIconButton"
            >
              {justCopied ? <CheckIcon /> : <LinkIcon />}
            </StyledIconButton>
          </Tooltip>
          {iAmAnAdmin &&
            <Tooltip
              title={i18n("Admin Settings")}
            >
              <span>
                <StyledLinkIconButton
                  to={`/church/${id}/admin`}
                  className="ChannelHeader-SettingsIconButton"
                >
                  {settingsIcon}
                </StyledLinkIconButton>
              </span>
            </Tooltip>
          }
        </HeaderText>
      }
    </Header>
  )
}

export default memo(ChannelHeader)