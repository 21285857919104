import { memo } from 'react'
import styled from 'styled-components'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import ImageIcon from '@material-ui/icons/Image'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from 'inline-i18n'

import useDataQuery from '../../../hooks/useDataQuery'
import { GET_STUDY_BIBLE_ITEM_TYPES, STUDY_BIBLE_CHANNELS } from '../../../utils/constants'

import MyChannelsListOptionsMenu from "./MyChannelsListOptionsMenu"

import sermonAudioChannelsQuery from '../../../graphql/queries/sermonAudioChannels'

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  margin: -1px -1px 0 -1px;
  padding: 15px;
  background-color: white;

  ${({ $hide }) => !$hide ? `` : `
    & > div:not(.MyChannelsListLine-Buttons) {
      opacity: .35;
    }
  `}
`

const ChannelSquare = styled.div`
  width: 43px;
  height: 43px;
  margin-right: 7px;
  border-radius: 5px;
  background-color: ${({ $color }) => $color};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgb(255 255 255/.9);
  font-size: 25px;

  &::after {
    content: "";
    position: absolute;
    inset: 0px;
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(255, 255, 255, 0.4) 41%, rgba(255, 255, 255, 0.3) 60%, rgba(0, 0, 0, 0.1) 100%);
    transition: all 0.3s ease-in-out 0s;
    background-size: 300%;
  }
`

const ChannelImage = styled.img`
  width: 43px;
  height: 43px;
  margin-right: 7px;
  object-fit: cover;
  border-radius: 5px;
`

const HandleContainer = styled.div`
  height: 36px;
  width: 36px;
`

const StyledDragHandleIcon = styled(DragHandleIcon)`
  padding: 6px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[500]};

  &:hover {
    color: ${({ theme }) => theme.palette.grey[900]};
    cursor: move;
  }
`

const ChannelInfo = styled.div`
  flex: 1;
  padding: 0 5px;
  overflow: hidden;

  ${({ $hide, theme }) => !$hide ? `` : `
    color: ${theme.palette.grey[500]};
  `}
`

const ChannelName = styled.div`
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
`

const OtherChannelInfo = styled.div`
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const NumSermons = styled.div`
`

const MyChannelsListLine = ({
  type,
  id,
  channelUrl,
  // channel,
  hide,
  toggleHide,
  dragHandleProps,
}) => {

  const query = ((channelUrl || ``).match(/(?:broadcasterID|speakerID)=[^&]+/) || [])[0]

  let { sermonAudioChannels: [ sermonAudioChannel ]=[], loading } = useDataQuery({
    sermonAudioChannelsQuery,
    variables: {
      query,
    },
    dataOnError: [],
    skip: !query,
  })

  if(!query) {
    const channel = STUDY_BIBLE_CHANNELS.find(channel => channel.channelUrl === channelUrl)
    if(channel) {
      sermonAudioChannel = {
        displayName: channel.label,
        imageURL: channel.iconLogoSrc,
      }
    }
  }

  let {
    aboutUs,  // for broadcasters
    bio,  // for speakers
    // broadcasterID,
    // speakerID,
    displayName=(loading ? i18n("Loading...") : `??`),
    imageURL,  // for broadcasters
    roundedThumbnailImageURL,  // for speakers
    location,
    minister,
    numberOfSermons,  // for broadcasters
    sermonCount,  // for speakers
    homePageURL,
    phone,
  } = sermonAudioChannel || {}

  const { label, color } = (type !== `CHANNEL` && GET_STUDY_BIBLE_ITEM_TYPES()[type]) || {}

  return (
    <Container $hide={hide}>

      {color &&
        <ChannelSquare $color={color}>
          {[ `INSIGHT` ].includes(type) && <NewReleasesIcon />}
          {[ `QUOTE`, `CREED` ].includes(type) && <FormatQuoteIcon />}
          {[ `SERMON`, `LECTURE`, `PODCAST`, `COURSE` ].includes(type) && <PlayCircleFilledIcon />}
          {[ `IMAGE` ].includes(type) && <ImageIcon />}
        </ChannelSquare>
      }

      {!color &&
        <ChannelImage
          src={imageURL || roundedThumbnailImageURL}
          className="dark-mode-exempt"
        />
      }

      <ChannelInfo>

        <ChannelName>
          {label || displayName}
        </ChannelName>

        <OtherChannelInfo>
          {
            channelUrl
            || ([ `INSIGHT`, `INTRODUCTION` ].includes(type) && i18n("From the Biblearc Team"))
            || ([ `MAP`, `GENEALOGY`, `TIMELINE` ].includes(type) && i18n("From Biblearc"))
            || i18n("Biblearc Team curated content")
          }
        </OtherChannelInfo>

        <NumSermons>
        </NumSermons>

      </ChannelInfo>

      <Buttons className="MyChannelsListLine-Buttons">

        <Tooltip
          title={i18n("Drag to reorder")}
        >
          <HandleContainer {...dragHandleProps}>
            <StyledDragHandleIcon />
          </HandleContainer>
        </Tooltip>

        <MyChannelsListOptionsMenu
          channelId={id}
          hide={hide}
          toggleHide={toggleHide}
          aboutUs={aboutUs || bio}
          displayName={displayName}
          imageURL={imageURL || roundedThumbnailImageURL}
          location={location}
          minister={minister}
          numberOfSermons={numberOfSermons || sermonCount}
          homePageURL={homePageURL}
          phone={phone}
        />

      </Buttons>

    </Container>
  )
}

export default memo(MyChannelsListLine)