import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n, getLocale } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import { useLocation } from "react-router-dom"
import { getLanguageInfo } from '@bibletags/bibletags-ui-helper'

import useVersionInfo from '../../../hooks/useVersionInfo'

import Header from '../../common/Header'
import Loading from '../../common/Loading'
import MarkdownContainer from '../../common/MarkdownContainer'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 20px;
  position: relative;
`

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  font-size: 16px;
`

const NameAndAbbr = styled.h1`
  font-weight: 300;
  margin-bottom: 0;
`

const Name = styled.span`
  display: inline-block;
  font-weight: bold;
`

const LanguageLine = styled.h2`
  font-weight: 300;
  font-size: 22px;
  margin-top: 0;
`

const Language = styled.span`
`

const Copyright = styled(MarkdownContainer)`
  margin: 35px 0;
  font-size: 16px;

  p {
    margin: .8em 0;
  }
`

const PartialScope = styled.div`
  font-style: italic;
  margin-bottom: 10px;
`

const HebrewOrdering = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`

const TechInfo = styled.div`

  table {
    border-collapse: collapse;
  }

  td {
    border: 2px solid white;
    background: ${({ theme }) => theme.palette.grey[200]};
    padding: 4px 7px;
    font-size: 15px;
  }

`

const TechInfoHeading = styled.h3`
  margin: 50px 0 10px;
  font-size: 16px;
`

const LinkLikeSpan = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.light};
  }
`

const LastUpdated = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
  margin-top: 30px;
`

const Version = ({ ...props }) => {

  const location = useLocation()
  const id = location.pathname.split('/')[2]

  const { version } = useVersionInfo(id)

  const {
    abbr,
    name,
    wordDividerRegex,
    copyright,
    versificationModel,
    skipsUnlikelyOriginals,
    extraVerseMappings,
    hebrewOrdering,
    partialScope,
    languageId,
    updatedAt,
  } = version || {}

  const openTabWithExtraVerseMappings = useCallback(
    event => {
      event.preventDefault()
      var x = window.open()
      x.document.open()
      x.document.write(`<html><body><pre>${JSON.stringify(extraVerseMappings, null, 2)}</pre></body></html>`)
      x.document.close()
    },
    [ extraVerseMappings ],
  )

  return (
    <>

      <Header
        {...props}
        title={name}
      />

      <Container>
        <Content>

          {!version && <Loading />}

          {!!version &&
            <>

              <NameAndAbbr>
                {i18nReact("{{title}} ({{subtitle}})", {
                  title: (
                    <Name>
                      {name}
                    </Name>
                  ),
                  subtitle: abbr,
                })}
              </NameAndAbbr>

              <LanguageLine>
                {languageId.split('+').map(lId => {
                  const { nativeName=`?`, englishName } = getLanguageInfo(lId)
                  return (
                    <React.Fragment key={lId}>
                      <Language>
                        {
                          (!englishName || englishName === nativeName)
                            ? nativeName
                            : (
                              i18n("{{title}} ({{subtitle}})", {
                                title: nativeName,
                                subtitle: englishName,
                              })
                            )
                        }
                      </Language>
                      {` `}
                    </React.Fragment>
                  )
                })}
              </LanguageLine>

              <Copyright>
                {copyright}
              </Copyright>

              {partialScope === 'ot' &&
                <PartialScope>
                  {i18n("Old Testament only.")}
                </PartialScope>
              }

              {partialScope === 'nt' &&
                <PartialScope>
                  {i18n("New Testament only.")}
                </PartialScope>
              }

              {!!hebrewOrdering &&
                <HebrewOrdering>
                  {i18n("Note: Uses Hebrew ordering of the OT.")}
                </HebrewOrdering>
              }

              <TechInfo>

                <TechInfoHeading>
                  {i18n("Technical Information")}
                </TechInfoHeading>

                <table>
                  <tbody>
                    <tr>
                      <td>{i18n("Word division regex")}</td>
                      <td>{wordDividerRegex || i18n("Default")}</td>
                    </tr>
                    <tr>
                      <td>{i18n("Versification model")}</td>
                      <td>{versificationModel.toUpperCase()}</td>
                    </tr>
                    <tr>
                      <td>{i18n("Skips unlikely originals")}</td>
                      <td>{skipsUnlikelyOriginals ? i18n("Yes") : i18n("No")}</td>
                    </tr>
                    <tr>
                      <td>{i18n("Extra verse mappings")}</td>
                      {!!extraVerseMappings &&
                        <td onClick={openTabWithExtraVerseMappings}>
                          <LinkLikeSpan>
                            {i18n("View")}
                          </LinkLikeSpan>
                        </td>
                      }
                      {!extraVerseMappings &&
                        <td>
                          {i18n("None")}
                        </td>
                      }
                    </tr>
                  </tbody>
                </table>

                <LastUpdated>
                  {i18nReact("Version last updated: {{date}}", {
                    date: new Date(updatedAt).toLocaleDateString(getLocale()),
                  })}
                </LastUpdated>

              </TechInfo>

            </>
          }

        </Content>
      </Container>

    </>
  )
}

export default memo(Version)