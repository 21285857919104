import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

import useIsLoggedIn from '../../../hooks/useIsLoggedIn'

import SignInButton from '../../common/SignInButton'
import LinkButton from '../../common/LinkButton'
import JoinTheMailingListButton from '../../common/JoinTheMailingListButton'

const Container = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.3;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 0;
  gap: 7px;
  flex-wrap: wrap;

  & > div {
    margin: 0;
  }
`

const JoinContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 5px;
`

const MailingListDiscount = styled.div`
  font-weight: 300;
  font-size: 12px;
`

const StyledButton = styled(Button)`
  background: rgb(0 0 0/.7);
  color: white;
  font-size: 18px;
  margin: -2px 0 13px;

  &:hover {
    background: black;
  }
`

const HomeSubscribeSignIn = ({
  fullScreen,
  toggleSliderSectionOpen,
}) => {

  const isLoggedIn = useIsLoggedIn()

  return (
    <Container>

      <ButtonContainer>

        <LinkButton
          to="/subscribe"
          variant="contained"
          color="secondary"
          disableElevation
          size="small"
        >
          {i18n("Subscribe")}
        </LinkButton>

        {!isLoggedIn &&
          <SignInButton
            size="small"
          />
        }

      </ButtonContainer>

      {fullScreen &&
        <StyledButton
          onClick={toggleSliderSectionOpen}
          variant="contained"
          disableElevation
        >
          {i18n("Read the Bible")}
        </StyledButton>
      }

      {!isLoggedIn &&
        <JoinContainer>
          <JoinTheMailingListButton />
          <MailingListDiscount>
            {i18n("...and get 50% off your first month")}
          </MailingListDiscount>
        </JoinContainer>
      }

    </Container>
  )
}

export default memo(HomeSubscribeSignIn)