import { memo, useRef } from 'react'
import styled from 'styled-components'

import { cloneObj } from '../../../utils/misc'
import useLongPress from '../../../hooks/useLongPress'

import TextContent from '../../common/TextContent'
import OriginalLanguageWordPopperContent from '../../passage/OriginalLanguageWordPopperContent'

const WordPart = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -3px 2px;
  padding: ${({ $bookId }) => $bookId <= 39 ? 4 : 0}px 5px 5px;
  border-radius: 4px;
  border: 1px solid transparent;

  .TextContent-tag-v, .TextContent-tag-vp {
    font-size: 17px;
  }

  cursor: pointer;

  ${({ $selectable, theme, $selected }) => !($selectable && !$selected) ? `` : `
    @media (hover: hover) {
      &:hover {
        background: ${theme.palette.grey[200]};
      }
    }
  `}

  ${({ $selected, theme }) => !$selected ? `` : `
    background: ${theme.palette.grey[200]};
    border-color: ${theme.palette.grey[800]};
  `}

  ${({ $comboColor }) => !$comboColor ? `` : `
    background: ${$comboColor};
    @media (hover: hover) {
      &:hover {
        background: ${$comboColor};
      }
    }
  `}

`

const OrigWordContainer = styled.div`
  [data-word-id], [data-word-part] {
    display: inline-block;
    position: relative;
  }
`

const TranslationWordsContainer = styled.div`
  font-size: 11px;
  font-weight: 300;
  line-height: 1;
  max-width: ${({ $approximateOrigWordLength }) => Math.max($approximateOrigWordLength, 75)}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const TranslationWords = styled.div`
  font-size: 13.5px;
  font-weight: 300;
  line-height: 1;
  direction: ${({ $isRTL }) => $isRTL ? "rtl" : "ltr"};
  text-align: ${({ $textAlign }) => $textAlign};
`

const TaggerOriginalWordPart = ({
  selectedOriginalWordIdAndPartNumbers,
  setSelectedOriginalWordIdAndPartNumbers,
  wordIdAndPartNumber,
  infoByWordIdAndPartNumber,
  setTags,
  tags,
  bookId,
  startChapter,
  pseudoPiece,
  approximateOrigWordLength,
  color,
  originalIsRTL,
  translationIsRTL,
  goSetPopperInfo,
  contextRef,
}) => {

  const id = wordIdAndPartNumber.split(`|`)[0]

  const ref = useRef()

  const { comboColor, translation } = (
    infoByWordIdAndPartNumber[wordIdAndPartNumber]
    || infoByWordIdAndPartNumber[id]
    || {}
  )

  const longPressEvents = useLongPress({
    onLongPress: () => {
      if(!wordIdAndPartNumber) return

      let newSelectedOriginalWordIdAndPartNumbers

      // update selected original words
      if(selectedOriginalWordIdAndPartNumbers.includes(wordIdAndPartNumber)) {
        newSelectedOriginalWordIdAndPartNumbers = (
          selectedOriginalWordIdAndPartNumbers.filter(wordIdAndPartNumber2 => (
            wordIdAndPartNumber2 !== wordIdAndPartNumber
          ))
        )
      } else {
        newSelectedOriginalWordIdAndPartNumbers = [
          ...selectedOriginalWordIdAndPartNumbers,
          wordIdAndPartNumber,
        ]
      }

      setSelectedOriginalWordIdAndPartNumbers(newSelectedOriginalWordIdAndPartNumbers)

      if(
        selectedOriginalWordIdAndPartNumbers.length > 0
        && newSelectedOriginalWordIdAndPartNumbers.length > 0
      ) {
        // unselect translation words connected to any of the selected original words
        const newTags = cloneObj(tags).filter(tag => (
          !tag.o.some(wordIdAndPartNumber => (
            newSelectedOriginalWordIdAndPartNumbers.includes(wordIdAndPartNumber)
          ))
        ))
        setTags(newTags)
      }

    },
    onClick: () => {
      const clickedTag = tags.find(({ o }) => o.includes(wordIdAndPartNumber))
      if(selectedOriginalWordIdAndPartNumbers.includes(wordIdAndPartNumber)) {
        const { lemma, morph, strong, text, children, wordNumberInVerse } = pseudoPiece
        goSetPopperInfo(
          {
            currentTarget: ref.current,
          },
          {
            Component: OriginalLanguageWordPopperContent,
            props: {
              versionId: "original",
              contextRef,
              wordNumberInVerse,
              lemma,
              morph,
              strong,
              form: text || children,
              id,
              goSetPopperInfo,
            },
          },
        )
      } else if(clickedTag) {
        setSelectedOriginalWordIdAndPartNumbers(cloneObj(clickedTag.o))
      } else {
        setSelectedOriginalWordIdAndPartNumbers([ wordIdAndPartNumber ].filter(Boolean))
      }
    },
  })

  if(pseudoPiece.tag === "zApparatusJson") return null  // TODO: eventually show this and allow user to tag to an alt reading

  return (
    <WordPart
      ref={ref}
      $selected={selectedOriginalWordIdAndPartNumbers.includes(wordIdAndPartNumber)}
      $selectable={!!wordIdAndPartNumber}
      $comboColor={comboColor}
      $bookId={bookId}
      {...longPressEvents}
    >

      <OrigWordContainer
        $id={id}
        $wordIdAndPartNumber={wordIdAndPartNumber}
        $infoByWordIdAndPartNumber={infoByWordIdAndPartNumber}
        $bookId={bookId}
      >
        <TextContent
          pieces={[pseudoPiece]}
          versionId="original"
          bookId={bookId}
          startChapter={startChapter}
          showCfs
          showNotes
          showCantillation
          showHebrewVowels
          showGreekAccents
        />
      </OrigWordContainer>

      <TranslationWordsContainer $approximateOrigWordLength={approximateOrigWordLength}>
        <TranslationWords
          style={{ color }}
          $textAlign={originalIsRTL ? (translationIsRTL ? 'left' : 'right') : `initial`}
          $isRTL={translationIsRTL}
        >
          {translation || ` `}
        </TranslationWords>
      </TranslationWordsContainer>

    </WordPart>
  )
}

export default memo(TaggerOriginalWordPart)