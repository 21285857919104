import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import useModalAnchor from '../../hooks/useModalAnchor'
import useThrottleCallback from '../../hooks/useThrottleCallback'

import OptionsPopper from '../common/OptionsPopper'
import Editor from '../editor/Editor'
import DangerMenuItem from '../common/DangerMenuItem'
import MenuDivider from '../common/MenuDivider'
import ColorChooser from '../common/ColorChooser'

const DEFAULT_MAX_WIDTH = 400

const StyledOptionsPopper = styled(OptionsPopper)`

  .OptionsPopper-Container {
    overflow: visible;
    color: black;
    box-shadow: 0px 1px 10px rgb(0 0 0/.25);
  }

  .ToolbarPlugin-Container {
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    height: 0;
    display: flex;
    align-items: flex-end;
    overflow: visible;
  }

  &[x-placement="bottom"] .ToolbarPlugin-Container {
    top: auto;
    bottom: -5px;
    align-items: flex-start;
  }

  .ToolbarPlugin-ButtonContainer {
    border: none;
    box-shadow: 0px 1px 10px rgb(0 0 0/.25);
    width: calc(100% - 37px);
  }

  &[x-placement="bottom"] .DotPopper-MoreIconButton {
    top: auto;
    bottom: -37px;
  }

`

const Container = styled.div`
  position: relative;
`

const StyledEditor = styled(Editor)`
  overflow: auto;
  padding: 0 15px;
  max-height: calc(100vh - 200px);
  min-width: 100px;

  ${({ $width }) => !$width ? `` : `
    width: ${$width}px;
  `}

  ${({ $maxWidth }) => !$maxWidth ? `` : `
    max-width: ${$maxWidth}px;
  `}
`

const MoreIconButton = styled(IconButton)`
  position: absolute;
  top: -37px;
  right: 0;
  padding: 7px;
  background: white;
  box-shadow: 0px 3px 10px rgb(0 0 0/.25);

  .MuiSvgIcon-root {
    font-size: 18px;
  }

  &:hover {
    background: ${({ theme }) => theme.palette.grey[100]};
  }
`

const DotPopper = ({
  anchorEl,
  moduleDot,
  goUpdateModuleDot,
  goDeleteModuleDot,
  inEditingMode,
  moduleWidth,
  ...otherProps
}) => {

  const [ initialContent, setInitialContent ] = useState()
  const open = initialContent !== undefined
  const [ defaultMaxWidth, setDefaultMaxWidth ] = useState(DEFAULT_MAX_WIDTH)

  const { anchorPosition, openModal, closeModal } = useModalAnchor()

  const maxWidth = Math.min((moduleWidth - 31) || Infinity, (inEditingMode ? DEFAULT_MAX_WIDTH : defaultMaxWidth))

  useLayoutEffectAsync(
    () => {

      setInitialContent(
        anchorEl
          ? moduleDot.content
          : undefined
      )

      if(anchorEl && /^\{"root":/.test(moduleDot.content)) {

        try {
          const json = JSON.parse(moduleDot.content)
          if(
            json.root.children.length === 1
            && json.root.children[0].type === `paragraph`
            && json.root.children[0].children.length === 1
          ) {
            const length = json.root.children[0].children[0].text.length
            if(length < 130) {
              setDefaultMaxWidth(200)
              return
            } else if(length < 240) {
              setDefaultMaxWidth(300)
              return
            }
          }
        } catch(error) {}

        setDefaultMaxWidth(DEFAULT_MAX_WIDTH)
      }

    },
    [ anchorEl, inEditingMode ],
  )

  const goSave = useCallback(
    content => {
      goUpdateModuleDot({ content })
    },
    [ goUpdateModuleDot ],
  )

  const [ goThrottledSave, flushSave ] = useThrottleCallback(goSave)

  const onClickDelete = useCallback(
    () => {
      closeModal()
      goDeleteModuleDot()
    },
    [ closeModal, goDeleteModuleDot ],
  )

  const onChangeColor = useCallback(
    ({ newValue }) => {
      goUpdateModuleDot({ color: newValue })
    },
    [ goUpdateModuleDot ],
  )

  return (
    <>

      <StyledOptionsPopper
        open={open}
        anchorEl={anchorEl}
        setMaxHeight
        bgColor={moduleDot.color}
        topOffset={109 + (inEditingMode ? 37 : 0)}
        bottomOffset={-10 + (inEditingMode ? 37 : 0)}
        {...otherProps}
      >
        <Container>

          {inEditingMode &&
            <MoreIconButton
              className="DotPopper-MoreIconButton"
              onClick={openModal}
              tabIndex={-1}
            >
              <MoreVertIcon />
            </MoreIconButton>
          }

          <Menu
            open={!!anchorPosition}
            onClose={closeModal}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
          >

            <ColorChooser
              skipColors={[ moduleDot.color ]}
              onChange={onChangeColor}
            >
              <MenuItem>
                {i18n("Change color")}
              </MenuItem>
            </ColorChooser>


            {/* <MenuItem
              // onClick={}
            >
              {i18n("Covert to sticky note")}
            </MenuItem> */}

            <MenuDivider />

            <DangerMenuItem
              onClick={onClickDelete}
            >
              {i18n("Delete")}
            </DangerMenuItem>

          </Menu>

          <StyledEditor
            className={`OptionsPopper-max-height-child DotPopper-StyledEditor-${moduleDot.id}`}
            editable={inEditingMode}
            initialContent={initialContent}
            onUpdate={goThrottledSave}
            onBlur={flushSave}
            $width={inEditingMode ? maxWidth : null}
            $maxWidth={inEditingMode ? null : maxWidth}
          />

        </Container>
      </StyledOptionsPopper>

    </>
  )
}

export default memo(DotPopper)