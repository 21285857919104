import React, { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import LaunchIcon from '@material-ui/icons/Launch'

import {
  STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE,
} from '../../utils/constants'

import NavLinkOrAWithDisable from '../common/NavLinkOrAWithDisable'
import LinkIconButton from '../common/LinkIconButton'

const Image = styled.img`
  vertical-align: middle;
`

const SourceImage = styled(Image)`
  max-width: ${STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE/2}px;
  max-height: ${STUDY_BIBLE_SOURCE_THUMBNAILS_MAX_SIZE/2}px;
  width: auto;
  height: auto;
  vertical-align: bottom;
`

const Source = styled.div`
  display: flex;
  padding: 15px;
  gap: 10px;
  align-items: flex-end;
  position: relative;
  justify-content: flex-end;
`

const SourceTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 10px;
`

const SourceText = styled.div`
  font-weight: 500;
`

const QuoteSource = styled.div`
  font-weight: 200;
  margin-bottom: 3px;
`

const StyledLinkIconButton = styled(LinkIconButton)`
  padding: 7px;
  margin: -10px 0 -8px;

  .MuiSvgIcon-root {
    width: 13px;
    height: 13px;
  }
`

const StyledNavLinkOrAWithDisable = styled(NavLinkOrAWithDisable)`
  transition: opacity .15s ease-in-out;

  ${({ disabled }) => disabled ? `` : `
    &:hover {
      opacity: .5;
    }
  `}
`

const StudyBibleItemSource = ({
  type,
  info,
  ...otherProps
}) => {

  const { sourceText, sourceUrl, sourceThumbnailImgUrl, sourceThumbnailImgDimensions={} } = info || {}

  if(!sourceText) return null

  return (
    <Source {...otherProps} >

      <SourceTextContainer>

        {[ `QUOTE` ].includes(type) &&
          <QuoteSource>
            {i18n("Quote Source")}
          </QuoteSource>
        }

        <SourceText>

          {sourceText}

          {!!sourceUrl &&
            <StyledLinkIconButton
              href={sourceUrl}
            >
              <LaunchIcon />
            </StyledLinkIconButton>
          }

        </SourceText>

      </SourceTextContainer>

      {!!sourceThumbnailImgUrl &&
        <StyledNavLinkOrAWithDisable
          to={sourceUrl}
          disabled={!sourceUrl}
        >
          <SourceImage
            src={sourceThumbnailImgUrl}
            style={sourceThumbnailImgDimensions}
            className="dark-mode-exempt"
          />
        </StyledNavLinkOrAWithDisable>
      }

    </Source>
  )
}

export default memo(StudyBibleItemSource)