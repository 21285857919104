import { memo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Button from '@material-ui/core/Button'
import copy from 'copy-to-clipboard'
import LinkIcon from '@material-ui/icons/Link'
import CheckIcon from '@material-ui/icons/Check'

import useSetTimeout from '../../../hooks/useSetTimeout'

import Header from "../../common/Header"
import LinkButton from '../../common/LinkButton'
import VideoPlayer from '../../common/VideoPlayer'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 80px;
  background-color: white;
  position: relative;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40vh;
`

const Heading = styled.div`
  margin: 70px 0 20px;
  font-size: 24px;
`

const Subheading = styled.div`
  margin: -10px 0 20px;
  font-size: 20px;
  font-weight: 300;
`

const FreeNow = styled.div`
  margin-top: 15px;
  background: ${({ theme }) => theme.palette.primary.veryLight};
  padding: 2px 7px;
  font-weight: bold;
`

const QuickSetup = styled.div`
  font-weight: 300;
  margin: 20px 0 30px;
`

const ButtonContainer = styled.div`
  margin: 15px 0 0;
`

const StyledLinkButton = styled(LinkButton)`
  .MuiButton-label {
    flex-direction: column;
  }
`

const CreateLinkButton = styled(StyledLinkButton)`
  padding: 16px;
  line-height: 1.2;

  .MuiButton-label {
    font-size: 15px;
    flex-direction: column;
  }
`

const TryFree = styled.div`
  text-transform: none;
  font-weight: 300;
  font-size: 14px;
`

const MustBePastor = styled.div`
  border: 3px dotted ${({ theme }) => theme.palette.grey[300]};
  padding: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  margin: 50px 0 0;

`

const NotPastor = styled.div`
  margin: 10px 0 15px;
  font-weight: 300;
`

const PromoImage = styled.img`
  margin: 30px 0;
  width: 588px;
  height: 750px;
  max-width: calc(100vw - 30px);
  object-fit: contain;

  @media (max-width: 620px) {
    height: auto;
  }
`

const TagLine = styled.div`
  font-weight: 700;
  font-size: 23px;
`

const About = styled.div`
  font-weight: 300;
  font-size: 20px;
`

const Table = styled.div`
  font-size: 15px;
  position: relative;

  /* ::before {
    content: "";
    background: ${({ theme }) => theme.palette.divider};
    position: absolute;
    bottom: 4px;
    right: calc(100% - 5px);
    height: 1px;
    width: 120%;
    transform-origin: top right;
    transform: rotate(145deg);
  } */
`

const Row = styled.div`
  display: table-row;
`

const HeaderCell = styled.div`
  display: table-cell;
  padding: 5px 25px;
  font-weight: 300;

  &:nth-child(2) {
    padding-left: 0;
  }
`

const Cell = styled.div`
  display: table-cell;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 5px 25px;

  &:nth-child(2) {
    padding-left: 0;
    font-weight: 500;
  }
`

const Includes = styled.div`
  margin: 60px 0 80px;
`

const IncludesHeading = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 20px;
  text-transform: uppercase;
`

const IncludesItem = styled.div`
  margin: 30px 0 20px;
  line-height: 1.2;
`

const IncludesImage = styled.img`
  height: 100px;
  max-width: 100%;
  margin-bottom: 20px;
  object-fit: contain;
`

const IconExamples = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
`

const IconExample = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 73px;
  font-size: 12px;
  gap: 7px;
`

const IconExampleImage = styled.img`
  width: 55px;
  height: 55px;
`

const HostsIncludesImage = styled(IncludesImage)`
  height: 81px;
  margin-bottom: 30px;
`

const BookmarksIncludesImage = styled(IncludesImage)`
  margin: -15px 0 5px;
`

const CompareIncludesImage = styled(IncludesImage)`
  height: 150px;
`

const CfsIncludesImage = styled(IncludesImage)`
  height: 180px;
`

const OrigToolsIncludesImage = styled(IncludesImage)`
  height: 270px;
`

const DiscussIncludesImage = styled(IncludesImage)`
  margin: -10px 0 5px;
`

const PlayerIncludesImage = styled(IncludesImage)`
  margin: -10px 0 30px;
`

const Ready = styled.div`
  font-weight: bold;
  margin-bottom: 25px;
  font-size: 30px;
`

const VideoContainer = styled.div`
  width: 650px;
  max-width: 100%;
  margin: 50px 0 0;
`

const AboutChurchBibles = ({ ...props }) => {

  const [ justCopied, setJustCopied ] = useState(false)
  const [ setJustCopiedTimeout ] = useSetTimeout()

  const copyLink = useCallback(
    () => {
      copy(`${i18n("Hi, Pastor. Would you check out Church-Specific Study Bibles from Biblearc? I think this would be great for our church!")}\n\n${document.location.href}`)
      setJustCopied(true)
      setJustCopiedTimeout(() => {
        setJustCopied(true)
        setJustCopiedTimeout(() => setJustCopied(false), 1500)
      }, 200)
    },
    [ setJustCopied, setJustCopiedTimeout ],
  )

  const createButton = (
    <ButtonContainer>
      <CreateLinkButton
        to="/create-a-church-bible"
        variant="contained"
        disableElevation
        color="primary"
      >
        {i18n("Create an app for your church")}
        <TryFree>
          {i18n("Try free for 14 days. No CC required.")}
        </TryFree>
      </CreateLinkButton>
    </ButtonContainer>
  )

  return (
    <>

      <Header
        {...props}
        showBiblearcLogo
      />

      <Container>
        <Content>

          <PromoImage
            className="dark-mode-exempt"
            src="/cssb_phone_tiny.png"
          />
          {/* same image as church-bibles */}

          <TagLine>
            {i18n("For Those Who Love the Bible + Love Their Church")}
          </TagLine>

          <About>
            {i18n("A study Bible with sermons from your church next to the passages they teach on.")}
          </About>

          {/* <Heading>
            {i18n("Create a Church-Specific Study Bible")}
          </Heading> */}

          <VideoContainer>
            <VideoPlayer src="https://vimeo.com/947839749" />
          </VideoContainer>

          <MustBePastor>

            {i18n("Note: You must be a pastor/elder (or acting on behalf of your pastor) to create a Bible for your church.")}

            <NotPastor>
              {i18n("Not a pastor? Ask him to set one up, or get his permission for you to do so.")}
            </NotPastor>

            <ButtonContainer>
              <Button
                variant="outlined"
                onClick={copyLink}
                color={justCopied ? `primary` : `default`}
                startIcon={justCopied ? <CheckIcon /> : <LinkIcon />}
              >
                {i18n("Copy a Sample Request + the Link")}
              </Button>
            </ButtonContainer>

          </MustBePastor>

          <Heading>
            {i18n("One-Time Setup Fee for a Church")}
          </Heading>

          <Subheading>
            {i18n("(Free for Church Members)")}
          </Subheading>

          <Table>
            <Row>
              <HeaderCell>{i18n("Regular Attenders")}</HeaderCell>
              <HeaderCell>{i18n("Cost")}</HeaderCell>
            </Row>
            <Row>
              <Cell>{i18n("Up to 99")}</Cell>
              <Cell>{i18n("${{amount}}", { amount: 100 })}</Cell>  {/* eslint-disable-line no-template-curly-in-string */}
            </Row>
            <Row>
              <Cell>{i18n("100–399")}</Cell>
              <Cell>{i18n("${{amount}}", { amount: 250 })}</Cell>  {/* eslint-disable-line no-template-curly-in-string */}
            </Row>
            <Row>
              <Cell>{i18n("400–999")}</Cell>
              <Cell>{i18n("${{amount}}", { amount: 400 })}</Cell>  {/* eslint-disable-line no-template-curly-in-string */}
            </Row>
            <Row>
              <Cell>{i18n("1000+")}</Cell>
              <Cell>{i18n("${{amount}}", { amount: 750 })}</Cell>  {/* eslint-disable-line no-template-curly-in-string */}
            </Row>
          </Table>

          <FreeNow>
            {i18n("Free for churches in the developing world!")}
          </FreeNow>

          <QuickSetup>
            {i18n("(10 minute setup + no maintanence required!)")}
          </QuickSetup>

          {createButton}

          <ButtonContainer>
            <StyledLinkButton
              to="/church-bibles-faq"
              variant="contained"
              disableElevation
            >
              {i18n("Frequently Asked Questions")}
            </StyledLinkButton>
          </ButtonContainer>

          <Includes>

            <IncludesHeading>
              {i18n("Includes")}
            </IncludesHeading>

            <IncludesItem>
              {i18n("Custom App Icon and Name")}
            </IncludesItem>

            <IconExamples>
              <IconExample>
                <IconExampleImage src="/icon_example_1.png" className="dark-mode-exempt" />
                FBRC
              </IconExample>
              <IconExample>
                <IconExampleImage src="/icon_example_2.png" className="dark-mode-exempt" />
                W. Baptist
              </IconExample>
              <IconExample>
                <IconExampleImage src="/icon_example_3.png" className="dark-mode-exempt" />
                First Pres
              </IconExample>
            </IconExamples>

            <IncludesItem>
              {i18n("Your Church’s Sermons Drawn From Where You Host Them")}
            </IncludesItem>

            <HostsIncludesImage src="/hosts.png" />

            <IncludesItem>
              {i18n("Remembers Your Place in Each Sermon")}
            </IncludesItem>

            <PlayerIncludesImage src="/mini_player.png" className="dark-mode-exempt" />

            <IncludesItem>
              {i18n("Bookmark Specific Places Within Sermons")}
            </IncludesItem>

            <BookmarksIncludesImage src="/bookmarks.png" />

            <IncludesItem>
              {i18n("Discuss Sermons as a Church")}
            </IncludesItem>

            <DiscussIncludesImage src="/discussion.png" className="dark-mode-exempt" />

            {/* <IncludesItem>
              {i18n("Interactive Maps + Timeline")}
            </IncludesItem>

            <IncludesImage src="" /> */}

            <IncludesItem>
              {i18n("One-Click Comparison of Translations")}
            </IncludesItem>

            <CompareIncludesImage src="/compare.png" />

            <IncludesItem>
              {i18n("Follow Cross-References Without Losing Your Place")}
            </IncludesItem>

            <CfsIncludesImage src="/cfs.png" />

            <IncludesItem>
              {i18n("Powerful Bible Search")}
            </IncludesItem>

            <CfsIncludesImage src="/search.png" />

            <IncludesItem>
              {i18n("Original Language Tools")}
            </IncludesItem>

            <OrigToolsIncludesImage src="/orig_tools.png" />


          </Includes>

          <Ready>
            {i18n("Ready?")}
          </Ready>

          {createButton}

        </Content>

      </Container>

    </>
  )
}


export default memo(AboutChurchBibles)

        // Create a Study Bible app for Your Church /about-church-bibles
          // Not a pastor? Ask your pastor to set one up OR get his permission to do it on his behalf.
          // One-Time Setup Fee for the Church // Free for Church Members
            // Setup Fee
              // Regular attenders
                // up to 99 - $100
                // 100-399 - $250
                // 400-999 - $400
                // 1000+ - $600
            // Early Adoption Promotion: During May, setup fee is waved!
            // Setup fee is always waved for the developing world
        // 10 minutes to set up, Zero maintanence required
        // Create button
        // Includes
          // Your own custom app icon, app name, etc
          // Your church's sermons drawn from sermonaudio, youtube, etc. in a video player ideal for accompanying Bible study
            // remembers your spot
            // like, bookmark, and share
            // discuss as a church
          // Interactive Maps + Timeline
          // One-click comparison of translations
          // Follow cross-references without losing your place
          // Simple and complex search
          // Original language tools
        // Demo: BBC, Sandy Ridge
        // Create button
